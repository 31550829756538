import { AxiosError } from 'axios';
import { FREEDOM_PAY } from '../../../config/backendServicesEndpoints';
import { http } from '../../../services/http';

export interface Validate3DSPayload {
  eci: string;
  paResStatus: string;
  cavv: boolean;
  enrolled: string;
}

// @todo: theres already an axios instance in  ./services/http.ts
// that implements a logger interceptors
// and x-api-key
// since this hits our API we need to probably log that?

const validate3DS = async (
  payload: Validate3DSPayload
): Promise<boolean | null> => {
  return http
    .post(`${FREEDOM_PAY}/3ds`, payload)
    .then((resposen) => resposen.data)
    .catch(() => {
      // dont care what it error is, if 3ds doesnt pass we dont continue
      return null;
    });
};

export default validate3DS;
