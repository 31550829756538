import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { BillingFormValues } from '../CollectBilling/CollectBilling';
import Button from '@mui/material/Button';

interface BillingInfoDisplayProps {
  billingInfo: BillingFormValues;
  handleEditClick: () => void;
}

const Title = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(9),
  fontWeight: 600
}));

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  fontWeight: 400,
  marginTop: '14px'
});

const Name = styled(Typography)({
  fontWeight: 600
});

const BillingText = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px'
});

const EditButton = styled(Button)(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  color: theme.colors.base.black,
  fontSize: theme.spacing(3.5),
  padding: 0,
  lineHeight: theme.spacing(5),
  textDecorationLine: 'underline',
  cursor: 'pointer',
  justifyContent: 'start',
  marginTop: theme.spacing(2)
}));

const BillingInfoDisplay = ({
  billingInfo,
  handleEditClick
}: BillingInfoDisplayProps) => {
  const { t } = useTranslation();
  const {
    BillingFirstName,
    BillingLastName,
    Email,
    MobilePhone,
    BillingAddress1,
    BillingCity,
    Country
  } = billingInfo;
  return (
    <>
      <Title>{t('restaurant.checkout.billingInformation')}</Title>
      <Container>
        <Name>{`${BillingFirstName} ${BillingLastName}`}</Name>
        <BillingText>{Email}</BillingText>
        <BillingText>{MobilePhone}</BillingText>
      </Container>
      <Container>
        <BillingText>{BillingAddress1}</BillingText>
        <BillingText>{`${BillingCity}, ${Country?.name}`}</BillingText>
        <EditButton onClick={handleEditClick}>
          {t('restaurant.confirm.edit')}
        </EditButton>
      </Container>
    </>
  );
};

export default BillingInfoDisplay;
