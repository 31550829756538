import { RouteObject, useRoutes } from 'react-router-dom';
import StoreClosed from '../features/Stores/StoreClosed';
import AppVersion from '../templates/AppVersion/AppVersion';
import Cart from '../features/Cart';
import Checkout from '../features/Checkout';
import Menu from '../features/Menu/Menu';
import MenuItem from '../features/MenuItem';
import { t } from 'i18next';
import NotFoundError from '../features/NotFoundError/NotFoundError';
import '../i18n';
import Stores from '../features/Stores';
import OrderStatus from '../features/OrderStatus/OrderStatus';
import AppRoute from './AppRoute';
import WelcomeScreen from '../features/WelcomeScreen';

export const routeObj: RouteObject[] = [
  { path: AppRoute.HOME, element: <WelcomeScreen /> },
  { path: AppRoute.STORES, element: <Stores /> },
  {
    path: AppRoute.MENU,
    element: <Menu />
  },
  { path: AppRoute.ORDER_STATUS, element: <OrderStatus /> },
  {
    path: AppRoute.MENU_ITEM,
    element: <MenuItem />
  },
  {
    path: AppRoute.MENU_ITEM_EDIT,
    element: <MenuItem />
  },
  {
    path: AppRoute.CART,
    element: <Cart />
  },
  {
    path: AppRoute.CHECKOUT,
    element: <Checkout />
  },
  { path: AppRoute.CLOSED, element: <StoreClosed /> },
  { path: AppRoute.CLOSED_ALT, element: <StoreClosed /> },
  { path: AppRoute.VERSION, element: <AppVersion /> },
  {
    path: AppRoute.SITE_NOT_FOUND,
    element: <NotFoundError text={t('error.pageNotFound')} />
  },
  {
    path: AppRoute.STORE_NOT_FOUND,
    element: <NotFoundError text={t('error.pageNotFound')} />
  },
  { path: '*', element: <NotFoundError text={t('error.pageNotFound')} /> }
];

export const Routes = () => {
  return useRoutes(routeObj);
};
