import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { useMenu } from '../../../../hooks/useMenu';
import { useStore } from '../../../../hooks/useStore';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';

interface MenuNavProps {
  tabIndex: number;
  setTab: (category: string, scrollWait: Promise<void> | undefined) => void;
}

const TabContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'scrollPosition'
})<{ scrollPosition: number }>(({ theme, scrollPosition }) => ({
  position: 'sticky',
  top: 52,
  paddingLeft: 16,
  marginLeft: -48,
  marginRight: -16,
  backgroundColor: theme.colors.base.white,
  zIndex: 1000,
  boxShadow: scrollPosition > 210 ? '0px 9px 8px -8px #666' : 'none'
}));

const MenuNav = ({ tabIndex = 0, setTab }: MenuNavProps) => {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const { data } = useMenu(store ? store.id : '');
  const categoryLabels = useMemo(
    () => (data ? data.menuCategories : []),
    [data]
  );
  const categoryClasses = categoryLabels.map((category) => {
    // Extract numbers from the beginning of the string
    const match = category.match(/^\d+/);
    const numberAtStart = match ? match[0] : '';

    // Remove numbers from the beginning of the string
    let str = category.replace(/^\d+/, '');

    // Remove any non-alphanumeric characters except spaces
    str = str.replace(/[^\w\s]/g, '');

    // Replace spaces with dashes
    str = str.replace(/\s+/g, '-');

    // Append the number at the end if it exists
    if (numberAtStart) {
      str += '-' + numberAtStart;
    }

    return str;
  });

  const [scrollPosition] = useState<number>(0);

  const waitForScroll = (offsetPosition: number) => {
    return new Promise<void>((resolve) => {
      const failed = setTimeout(() => {
        resolve();
      }, 1000);
      const scrollHandler = () => {
        if (self.scrollY === offsetPosition) {
          window.removeEventListener('scroll', scrollHandler);
          clearTimeout(failed);
          resolve();
        }
      };
      if (self.scrollY === offsetPosition) {
        clearTimeout(failed);
        resolve();
      } else {
        window.addEventListener('scroll', scrollHandler);
      }
    });
  };

  const scroll = (val: number) => {
    const currentPosition = window.scrollY;
    const section = document.querySelector<HTMLElement>(
      `.${categoryClasses[val]}`
    );
    const h3 = section?.getElementsByTagName('h3')[0];
    const headerOffset = 105;
    const sectionPosition = h3?.getBoundingClientRect().top;
    let offsetPosition = sectionPosition
      ? sectionPosition - headerOffset + currentPosition
      : 0;
    const maximumScroll = document.body.scrollHeight - window.innerHeight;
    offsetPosition = Math.min(offsetPosition, maximumScroll);
    if (section) {
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
      return waitForScroll(offsetPosition);
    }
    return;
  };

  const logEvent = useAnalytics();

  const handleChange = async (_: unknown, newValue: number) => {
    setTab(categoryLabels[newValue], scroll(newValue));
  };

  return (
    <TabContainer
      scrollPosition={scrollPosition}
      data-testid="tab-container"
      role="tabpanel"
    >
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="sparkd mobile web menu categories"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#1A1A1A'
          }
        }}
      >
        {' '}
        {categoryLabels.map((category, index) => (
          <Tab
            label={category}
            key={index}
            aria-label={categoryClasses[index]}
            role="tab"
            tabIndex={index}
            onClick={() => {
              logEvent('category_selection', {
                category_name: category
              });
            }}
            data-testid={`${categoryClasses[index]}-tab`}
          />
        ))}
      </Tabs>
    </TabContainer>
  );
};

export default MenuNav;
