import { ProcessOrderResponse } from '../../types/fetch';
import { ProcessOrderProcResponseResult } from '../../types/ProcessOrderProcResponseResult';

const processOrderProcResponse = (
  orderProcResponse: ProcessOrderResponse
): ProcessOrderProcResponseResult => {
  const orderProcessResponse = orderProcResponse.data.response;
  const publicOrderId = orderProcResponse.data.publicOrderId;
  const eatTransactionId = orderProcResponse.data.eatTransactionId;
  return {
    orderProcessResponse,
    publicOrderId,
    eatTransactionId
  };
};

export default processOrderProcResponse;
