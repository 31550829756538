import { useParams } from 'react-router-dom';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useStore } from '../../hooks/useStore';
import { CartProviderV2 } from '../../contexts/Cart/CartContextV2';
import CheckoutContent from './components/CheckoutContent/CheckoutContent';
import useStoreClosedCheck from '../../hooks/useStoreClosedCheck';
import { Helmet } from 'react-helmet';
import { useColorConfig } from '../../hooks/useColorConfig';
import { ThemeProvider } from '@mui/material';
import AppLayout from '../../layouts/AppLayout';
import { useState } from 'react';
import Recaptcha from '../../components/Recaptcha/Recaptcha';
import { PaymentGatewayType } from '../../types/fetch';
import { appConfig } from '../../config/appConfig';

const Checkout = () => {
  const { storeId: shortId } = useParams();
  const { partnerConfig: siteConfig, paymentGateway } = useSiteConfig();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const theme = useColorConfig();
  const [shouldDisplayAppHeader, setShouldDisplayAppHeader] = useState(true);
  const showRecaptcha =
    appConfig.recaptchaEnabled &&
    paymentGateway?.paymentGateway === PaymentGatewayType.FreedomPay;

  useStoreClosedCheck(store);

  if (!store || !shortId) {
    return null;
  }

  const handlePaymentDrawerOpen = (isOpen: boolean) => {
    // hide app header when user is entering payment information
    setShouldDisplayAppHeader(!isOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppLayout shouldDisplay={shouldDisplayAppHeader}>
        <CartProviderV2 shortId={shortId}>
          <Helmet>
            <title>Checkout</title>
          </Helmet>
          <CheckoutContent
            store={store}
            siteConfig={siteConfig}
            paymentGateway={paymentGateway}
            onOpenPaymentDrawer={handlePaymentDrawerOpen}
          />
          {showRecaptcha && <Recaptcha location={'CHECKOUT'} />}
        </CartProviderV2>
      </AppLayout>
    </ThemeProvider>
  );
};

export default Checkout;
