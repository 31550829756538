export interface Response {
  title: string | null;
  description: string | null;
}

export const getErrorDialogText = (
  error: string | undefined | null
): Response => {
  const response: Response = { title: null, description: null };

  if (!error) {
    return response;
  }

  // Primary Error code docs: https://stripe.com/docs/error-codes
  // Card payment error code docs: https://stripe.com/docs/declines/codes
  switch (error) {
    case 'incorrect_zip': {
      response.title = 'error.zipcode.title';
      response.description = 'error.zipcode.description';
      break;
    }
    case 'invalid_expiry_month': {
      response.title = 'error.invalidExpiryMonth.title';
      response.description = 'error.invalidExpiryMonth.description';
      break;
    }
    case 'invalid_expiry_year': {
      response.title = 'error.invalidExpiryYear.title';
      response.description = 'error.invalidExpiryYear.description';
      break;
    }
    case 'incorrect_cvc': {
      response.title = 'error.incorrectCVC.title';
      response.description = 'error.incorrectCVC.description';
      break;
    }
    case 'incorrect_number': {
      response.title = 'error.incorrectNumber.title';
      response.description = 'error.incorrectNumber.description';
      break;
    }
    case 'insufficient_funds': {
      response.title = 'error.insufficientFunds.title';
      response.description = 'error.insufficientFunds.description';
      break;
    }
    case 'expired_card': {
      response.title = 'error.expiredCard.title';
      response.description = 'error.expiredCard.description';
      break;
    }
    default: {
      response.title = 'error.processing.title';
      response.description = 'error.processing.description';
    }
  }
  return response;
};
