import { FirebaseApp, initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../config/appConfig';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { createContext } from 'react';

interface Props {
  children: React.ReactNode;
}

interface FirebaseContextProps {
  firebaseApp: FirebaseApp;
  analytics: Analytics;
}

export const FirebaseContext = createContext<FirebaseContextProps>(
  {} as FirebaseContextProps
);

export const FirebaseProvider: React.FC<Props> = ({ children }) => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const firebaseContextValues = {
    firebaseApp: app,
    analytics: analytics
  };

  return (
    <FirebaseContext.Provider value={firebaseContextValues}>
      {children}
    </FirebaseContext.Provider>
  );
};
