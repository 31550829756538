import {
  useState,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  FocusEvent,
  useRef
} from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { useCartV2 } from '../../../../hooks/useCartV2';
import CustomTipConfirmation from '../CustomTipConfirmation/CustomTipConfirmation';
import { CartCalculation } from '../../../Cart/types';
import Drawer from '@mui/material/Drawer';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';

interface CustomTipInputProps {
  disabled: boolean;
  cartCalculations: { data: CartCalculation };
}

const StyledTipField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

const StyledCustomTipConfirmationDrawer = styled(Drawer)(() => ({
  zIndex: 1400,
  '& .MuiPaper-root': {
    overflowY: 'visible'
  }
}));

const CustomTipInput = ({
  disabled,
  cartCalculations
}: CustomTipInputProps) => {
  const { t } = useTranslation();
  const { setTip, tip } = useCartV2();
  const [tipAmount, setTipAmount] = useState<string>(
    tip.value ? tip.value.toFixed(2).toString() : '0.00'
  );
  const [isFocused, setIsFocused] = useState<boolean>(true);
  const { partnerConfig: siteConfig } = useSiteConfig();

  const inputRef = useRef<HTMLInputElement>();
  const [showCustomTipConfirmation, setShowCustomTipConfirmation] =
    useState(false);

  const blockInvalidChar = (e: KeyboardEvent<HTMLInputElement>) => {
    return ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
  };

  const adornment = (currency: string) => {
    const settings = {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };
    const locale = currency.toLowerCase() === 'gbp' ? 'en-GB' : 'en-US';
    return (0).toLocaleString(locale, settings).replace(/\d/g, '').trim();
  };

  const formatPrice = (price: string) => {
    if (price.length === 0 || price.length > 16 || price === '') {
      return tipAmount;
    }
    price = parseInt(price.replace('.', ''), 10).toString(); // remove decimal point and padded start 0's
    const paddedPrice = price.padStart(3, '0');
    const cents = paddedPrice.slice(-2);
    const dollars = paddedPrice.slice(0, -2);
    return `${dollars}.${cents}`;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tipValue = e.target.value;
    setTipAmount(formatPrice(tipValue));
  };

  const handleCustomTip = (customTip: string) => {
    const preTipTotal =
      cartCalculations.data.summary.subtotal +
      cartCalculations.data.summary.tax;
    if (Number(customTip) / preTipTotal > 0.5) {
      setShowCustomTipConfirmation(true);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const tipValue = e.target.value;
    handleCustomTip(tipValue);
    setTip({ type: 'CUSTOM', value: Number(tipAmount) });
  };

  const handlePressAddCustomTip = () => {
    setShowCustomTipConfirmation(false);
  };

  const handlePressChangeCustomTip = () => {
    setIsFocused(true);
    setShowCustomTipConfirmation(false);
  };

  useEffect(() => {
    if (disabled) {
      return;
    }

    setTip({ type: 'CUSTOM', value: Number(0) });
    setTipAmount('0.00');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (typeof tip.value === 'number' && Number(tipAmount) !== tip.value) {
      setTipAmount(tip.value.toFixed(2));
      if (inputRef.current) {
        inputRef.current?.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFocused || disabled) {
      return;
    }

    inputRef.current?.focus();
    setIsFocused(false);
  }, [isFocused, disabled]);

  return (
    <>
      <StyledTipField
        disabled={disabled}
        inputRef={inputRef}
        value={tipAmount ? tipAmount : ''}
        onChange={handleChange}
        label={t('restaurant.checkout.customTipLabel')}
        placeholder={t('restaurant.checkout.customTipPlaceholder')}
        type="number"
        id="tip-input"
        data-testid="tip-input"
        fullWidth
        InputLabelProps={{ required: false, shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {adornment(siteConfig.currency)}
            </InputAdornment>
          )
        }}
        onKeyDown={blockInvalidChar}
        onBlur={handleBlur}
      />
      <StyledCustomTipConfirmationDrawer
        anchor="bottom"
        open={showCustomTipConfirmation}
        hideBackdrop={false}
        data-testid="StyledCustomTipConfirmationDrawer"
      >
        <CustomTipConfirmation
          tip={cartCalculations.data.summary.tip}
          handlePressAddCustomTip={handlePressAddCustomTip}
          handlePressChangeCustomTip={handlePressChangeCustomTip}
        />
      </StyledCustomTipConfirmationDrawer>
    </>
  );
};

export default CustomTipInput;
