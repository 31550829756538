import { Cart } from '../../../contexts/Cart/CartContextV2';
import { CartItem } from '../types';
import isEqual from 'lodash.isequal';

export default function addItem(state: Cart, item: CartItem) {
  const itemExists = state.items.find(
    (current) =>
      current.productId === item.productId &&
      isEqual(
        Object.keys(current.modifiers).sort(),
        Object.keys(item.modifiers).sort()
      )
  );

  if (!itemExists) {
    return {
      ...state,
      items: state.items.concat(item)
    };
  }

  return {
    ...state,
    items: state.items.map((current) => {
      if (current.productId !== item.productId) {
        return current;
      }
      if (
        current.productId === item.productId &&
        isEqual(
          Object.keys(current.modifiers).sort(),
          Object.keys(item.modifiers).sort()
        )
      ) {
        return {
          ...current,
          quantity: current.quantity + item.quantity
        };
      }
      return current;
    })
  };
}
