import { AxiosResponse } from 'axios';
import { MENU } from '../config/backendServicesEndpoints';

import { http } from './http';

export const fetchMenu = async (
  siteId?: string
  // TODO: Look into Promise<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<AxiosResponse<any, any>> => {
  return http.get(`${MENU}/${siteId}/mobile`);
};
