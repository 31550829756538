import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const DialogBox = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5),
    textAlign: 'center'
  }
}));

const StyledTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3),

  fontWeight: 700,
  color: theme.colors.base.black
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.partner.primary,
  marginRight: theme.spacing(4.5),
  textAlign: 'center',
  display: 'block',
  margin: 'auto',
  marginBottom: theme.spacing(4)
}));

const LoadingText = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 400,
  color: theme.colors.base.black,
  padding: 0,
  margin: 0
}));

const ProcessingOrderDialog = () => {
  const { t } = useTranslation();

  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDelayed(true);
    }, 10000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <DialogBox
      open={true}
      data-testid="loading-dialog-box"
      style={{ zIndex: 2001 }}
    >
      <LoadingSpinner size={24} data-testid="loading-spinner" />
      <StyledTitle data-testid="loading-dialog-box-title">
        {delayed
          ? t('dialog.submittingOrderDelayed.title')
          : t('dialog.submittingOrder.title')}
      </StyledTitle>
      <LoadingText data-testid="loading-text">
        {delayed
          ? t('dialog.submittingOrderDelayed.text')
          : t('dialog.submittingOrder.text')}
      </LoadingText>
    </DialogBox>
  );
};

export default ProcessingOrderDialog;
