import { CartCalculationCartItem, CartItem } from '../../features/Cart/types';

const mapCartItemCalc = (
  data: CartItem[],
  items: CartCalculationCartItem[]
) => {
  return items.map((value) => {
    return {
      ...data[Number(value.clientItemId)],
      quantity: value.quantity,
      itemTotalDiscountPrice: value.itemTotalDiscountPrice,
      itemTotalPrice: value.itemTotalPrice
    };
  });
};

export default mapCartItemCalc;
