import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircleDiv from '../CircleDiv';

export interface StepCardProps {
  number: string;
  text: string;
  icon?: React.ComponentType | null;
}

const StepCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  backgroundColor: theme.colors.gray[25],
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: theme.spacing(2),
  boxShadow: '0px 2px 4px rgba(114, 119, 122, 0.2);'
}));

const Text = styled('p')(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(7.25),
  marginBottom: theme.spacing(4)
}));

const StepNumber = styled('p')(({ theme }) => ({
  color: theme.colors.base.white,
  fontSize: theme.spacing(5)
}));

const StepCard: React.FC<StepCardProps> = (props) => {
  const theme = useTheme();

  return (
    <StepCardContainer>
      <CircleDiv
        style={{
          backgroundColor: theme.colors.base.cinchio,
          color: theme.colors.base.white,
          margin: theme.spacing(-4),
          height: '34px',
          width: '34px'
        }}
      >
        <StepNumber>{props.number}</StepNumber>
      </CircleDiv>

      <Text>{props.text}</Text>

      {props.icon && <props.icon />}
    </StepCardContainer>
  );
};

export default StepCard;
