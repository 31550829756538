import Button from '@mui/material/Button';
import { DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const DialogBox = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-paper': {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5)
  }
}));

const StyledTitle = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(4),
  color: theme.colors.base.black,
  marginBottom: theme.spacing(4)
}));

const LoadingText = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 400,
  color: theme.colors.base.black,
  padding: 0,
  margin: 0
}));

const RetryOrderDialog = ({ onRetry }: { onRetry: () => void }) => {
  const { t } = useTranslation();

  return (
    <DialogBox
      open={true}
      data-testid="loading-dialog-box"
      style={{ zIndex: 2001 }}
    >
      <StyledTitle data-testid="loading-dialog-box-title">
        {t('dialog.paymentFailedRetry.title')}
      </StyledTitle>
      <LoadingText data-testid="loading-text">
        {t('dialog.paymentFailedRetry.text')}
      </LoadingText>

      <DialogActions>
        <Button onClick={onRetry}>{t('dialog.tryAgain')}</Button>
      </DialogActions>
    </DialogBox>
  );
};

export default RetryOrderDialog;
