import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'center' | 'left' | 'right';

interface SiteSnackbarProps {
  autoHideDuration?: number;
  handleClose?: () => void;
  message: React.ReactNode;
  open?: boolean;
  vertical?: VerticalPosition;
  horizontal?: HorizontalPosition;
  transitionDuration?: number;
  OverrideTransitionComponent?: (
    props: JSX.IntrinsicAttributes & SlideProps
  ) => JSX.Element;
}

const EatSnackbar = styled(Snackbar)<SnackbarProps>(({}) => ({
  width: '100%'
}));

const EatSnackBarContentContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.warning[500],
  borderRadius: theme.spacing(1),
  fontSize: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  boxShadow:
    '0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);'
}));

const SlideTransition = (props: JSX.IntrinsicAttributes & SlideProps) => {
  return <Slide {...props} direction="up" />;
};

const SiteSnackbar = ({
  handleClose,
  message,
  open,
  vertical = 'bottom',
  horizontal = 'center',
  transitionDuration,
  OverrideTransitionComponent,
  autoHideDuration = 5000
}: SiteSnackbarProps) => {
  return (
    <EatSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      TransitionComponent={
        OverrideTransitionComponent
          ? OverrideTransitionComponent
          : SlideTransition
      }
      transitionDuration={transitionDuration ? transitionDuration : 500}
      TransitionProps={{
        enter: false
      }}
      role="alert"
    >
      <EatSnackBarContentContainer>{message}</EatSnackBarContentContainer>
    </EatSnackbar>
  );
};

export default SiteSnackbar;
