import * as yup from 'yup';
import { postPhoneValidation } from '../../../services/phoneValidation';

const validatePhone = async (value: string, values: yup.TestContext) => {
  try {
    return await postPhoneValidation(value);
  } catch (e) {
    values.createError({ path: 'phone' });
    return false;
  }
};

const nameRegEx = new RegExp(/^[a-zA-Z.'\-áéíóúüñ ]*$/i);

const validationSchema = yup.object().shape({
  name: yup.string().trim().required().matches(nameRegEx),
  phone: yup
    .string()
    .trim()
    .required()
    .test('validated', async (value, values) => {
      const validated = await validatePhone(value as string, values);
      return validated as boolean;
    }),
  deliveryLocation: yup.string().min(1).max(10),
  optIn: yup.boolean()
});

export default validationSchema;
