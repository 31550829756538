import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import NavigationBox from '../../../../src/components/Accessibility/NavigationBox';

const StyledAppHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.breakpoints.values.lg,
  position: 'fixed',
  minHeight: theme.spacing(14),
  paddingTop: theme.spacing(2),
  zIndex: 2000,
  backgroundColor: theme.colors.partner.primary,
  paddingRight: theme.spacing(4),
  verticalAlign: 'middle'
}));

const AppHeader: FC<{ children: ReactNode }> = (props) => {
  return (
    <StyledAppHeader>
      <NavigationBox></NavigationBox>
      {props.children}
    </StyledAppHeader>
  );
};

export default AppHeader;
