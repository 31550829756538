import { useQuery } from '@tanstack/react-query';
import { fetchStores } from '../services/stores';

export default function useStores(siteId: string) {
  return useQuery(['partner-restaurants', siteId], () => fetchStores(siteId), {
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    retry: false,
    enabled: siteId !== ''
  });
}
