import { CONFIG_V3 } from '../config/backendServicesEndpoints';
import { ConfigInterface } from '../types/fetch';
import { http } from './http';

export const fetchSiteConfig = (
  siteShortCode: string
): Promise<ConfigInterface> => {
  return http
    .get(CONFIG_V3, { params: { shortCode: siteShortCode } })
    .then((response) => response.data);
};
