import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import OrderItemDetail from '../OrderItemDetail/OrderItemDetail';
import { CartCalculation } from '../../features/Cart/types';
import Typography from '@mui/material/Typography';
import mapCartItemCalc from '../../utils/Cart/mapCartItemCalc';
import { useCartV2 } from '../../hooks/useCartV2';

interface OrderSummaryProps {
  title?: string;
  removable: boolean;
  cartCalculations: {
    data: CartCalculation;
  };
}

const OrderSummaryContainer = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300]
}));

const OrderSummaryTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.spacing(6),
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(6.5)
}));

const OrderSummaryItemDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4)
}));

const OrderSummary = ({
  title,
  removable,
  cartCalculations
}: OrderSummaryProps) => {
  const { items: cartItems, promoCode } = useCartV2();
  const calcCartItems = mapCartItemCalc(cartItems, cartCalculations?.data.cart);
  return (
    <OrderSummaryContainer data-testid="order-summary">
      {title && (
        <OrderSummaryTitle variant="h3" data-testid="order-summary-title">
          {title}
        </OrderSummaryTitle>
      )}
      <List sx={{ padding: 0 }} data-testid="order-list">
        {calcCartItems &&
          calcCartItems.map((item, index) => (
            <div key={index} data-testid="order-item">
              <OrderItemDetail
                item={item}
                removable={removable}
                itemIndex={index}
                promoCode={promoCode}
              />
              {(calcCartItems.length - 1 !== index || !removable) && (
                <OrderSummaryItemDivider />
              )}
            </div>
          ))}
      </List>
    </OrderSummaryContainer>
  );
};

export default OrderSummary;
