import { ReactComponent as PopularFlagIcon } from '../../../../assets/images/logo-icons/popular_item_flag.svg';
import { ReactComponent as NewFlagIcon } from '../../../../assets/images/logo-icons/new_item_flag.svg';
import { ReactComponent as SeasonalFlagIcon } from '../../../../assets/images/logo-icons/seasonal_item_flag.svg';
interface MenuItemFlagProps {
  flags: string[];
  style?: React.CSSProperties;
}

const MenuItemFlag = ({ flags, style }: MenuItemFlagProps) => {
  if (flags.find((flag) => flag.toLowerCase() === 'new')) {
    return <NewFlagIcon style={style} data-testid="new-item-flag" />;
  }

  if (flags.find((flag) => flag.toLowerCase() === 'popular')) {
    return <PopularFlagIcon style={style} data-testid="popular-item-flag" />;
  }

  if (flags.find((flag) => flag.toLowerCase() === 'seasonal')) {
    return <SeasonalFlagIcon style={style} data-testid="seasonal-item-flag" />;
  }
  return null;
};

export default MenuItemFlag;
