import React from 'react';

export interface AllergenIconProps {
  dropShadow?: boolean;
  height?: string;
  width?: string;
  marginRight?: number;
  title?: string;
  AllergenSVG:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | string;
  testIdExtension?: string;
  ariaHidden?: boolean;
}

const AllergenIcon: React.FC<AllergenIconProps> = ({
  height = '24',
  width = '24',
  marginRight = 0,
  AllergenSVG,
  testIdExtension,
  dropShadow = false,
  ariaHidden = false
}) => {
  return (
    <AllergenSVG
      style={{ marginRight: marginRight }}
      filter={
        dropShadow
          ? `drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15))`
          : ''
      }
      height={height}
      width={width}
      data-testid={`allergen-icon ${testIdExtension}`}
      aria-hidden={ariaHidden}
    />
  );
};

export default AllergenIcon;
