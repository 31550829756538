import { useQuery } from '@tanstack/react-query';
import { MENU_QUERY_KEY } from '../constants';
import processMenuResponse from '../utils/API/processMenuResponse';
import { fetchMenu } from '../services/menuServices';

export const useMenu = (storeId: string) => {
  return useQuery(
    [MENU_QUERY_KEY, storeId],
    async () => processMenuResponse(await fetchMenu(storeId)),
    {
      enabled: storeId !== '',
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000
    }
  );
};
