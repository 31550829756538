import axios from 'axios';
import { backendConfig } from '../../../config/appConfig';
import { FREEDOM_PAY } from '../../../config/backendServicesEndpoints';

export interface GoogleInitializePayload {
  TotalPrice: string;
}

const googleInitialize = async (
  siteId: string,
  initializePayload: GoogleInitializePayload
) => {
  const initializeUrl = `${FREEDOM_PAY}/site/${siteId}/initialize/googlePay`;

  const requestBody = JSON.stringify(initializePayload);

  const axiosConfig = {
    method: 'POST',
    url: initializeUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };

  return axios(axiosConfig);
  // try {
  //   const response = await axios(axiosConfig);
  //   return response.data;
  // } catch (err) {
  //   throw err;
  // }
};

export default googleInitialize;
