import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';
import { FREEDOM_PAY } from '../../../config/backendServicesEndpoints';

export interface FPCartItem {
  productCode: string;
  productSKU: string;
  productName: string;
  productDescription: string;
  quantity: string;
  unitPrice: string;
  totalAmount: string;
  taxAmount: string;
}

export interface AuthorizeBilling {
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phoneNumber?: string;
  email?: string;
}

export interface AuthorizePayload {
  PaymentKey: string;
  PosSyncAttemptNumber: number;
  orderTimeStamp: string;
  chargeAmount: string;
  taxTotal: string;
  nameOnCard: string;
  invoiceNumber: string;
  items: FPCartItem[];
  bearerSessionKey: string;
  channel: 'kiosk' | 'mobile';
  billTo?: AuthorizeBilling;
}

const authorize = async (
  siteId: string,
  authorizePayload: AuthorizePayload
) => {
  const authorizeUrl = `${FREEDOM_PAY}/site/${siteId}/payments`;

  const requestBody = JSON.stringify(authorizePayload);

  const axiosConfig = {
    method: 'POST',
    url: authorizeUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default authorize;
