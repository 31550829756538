import { useTranslation } from 'react-i18next';
import SiteSnackbar from '../../../../components/SiteSnackbar/SiteSnackbar';

interface AlcoholSnackBarProps {
  open?: boolean;
  handleClose: () => void;
}

const AlcoholSnackBar = ({ open, handleClose }: AlcoholSnackBarProps) => {
  const { t } = useTranslation();
  return (
    <SiteSnackbar
      open={open}
      message={t('restaurant.menu.alcoholDisclaimer')}
      handleClose={handleClose}
    ></SiteSnackbar>
  );
};

export default AlcoholSnackBar;
