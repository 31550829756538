import { MenuItemModGroup } from '../../features/Menu/types';
import mapModItems from './mapModItems';

const mapAllModGroups = (modGroups: MenuItemModGroup[]): MenuItemModGroup[] => {
  return modGroups.map((modGroup) => ({
    ...modGroup,
    modItems: mapModItems(modGroup.modItems)
  }));
};

export default mapAllModGroups;
