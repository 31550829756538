const formatDisplayTime = (timeString: string) => {
  if (timeString === '') {
    return '';
  }

  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  return `${hour % 12 || 12}:${minute} ${hour < 12 ? 'AM' : 'PM'}`;
};

export default formatDisplayTime;
