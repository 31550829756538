import { MenuModifierItem } from '../../features/Menu/types';
import mapAllergenStrings from './mapAllergenStrings';
import mapAllAttributes from './mapAllAttributes';

const mapModItems = (modItems: MenuModifierItem[]): MenuModifierItem[] => {
  return modItems.map((modItem) => {
    let icons = mapAllergenStrings(modItem.allergens as string[]);
    icons = icons.concat(mapAllAttributes(modItem.attributes as string[]));
    return {
      ...modItem,
      allergens: icons
    };
  });
};

export default mapModItems;
