import { useEffect, useState } from 'react';
import { usePickupTimes } from './usePickupTimes';
import { parse } from 'date-fns';
import { useAnalytics } from './useAnalytics';
import { useCartV2 } from './useCartV2';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useSiteConfig } from './useSiteConfig';

export const useTimeDisplay = (storeId: string) => {
  const { setPickupTime, pickupTime } = useCartV2();
  const { data: times, isFetched } = usePickupTimes(storeId);
  const [pickupTimeDisplay, setPickupTimeDisplay] = useState<string | null>(
    null
  );
  const analytics = useAnalytics();
  const { partnerConfig: siteConfig } = useSiteConfig();

  const handleTimeSelection = (value: string | number | null) => {
    let time: Date | null = null;
    let analyticsTime = 0;

    if (value && !String(value).includes('ASAP')) {
      time = zonedTimeToUtc(
        parse(String(value), 'h:mm a', new Date()),
        siteConfig.timeZone
      );

      analyticsTime = time.getTime() - new Date().getTime() * 60;
    }

    analytics('change_pickup_time', { time_ahead: analyticsTime });
    setPickupTime(time);
  };

  useEffect(() => {
    if (!pickupTime) {
      setPickupTimeDisplay('ASAP');
      return;
    }

    setPickupTimeDisplay(
      utcToZonedTime(
        new Date(String(pickupTime)),
        siteConfig.timeZone
      ).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    );
  }, [siteConfig.timeZone, pickupTime]);

  const displayTimes: string[] = [];

  if (times) {
    times.forEach((time) => {
      let timeDisplay = utcToZonedTime(
        time.date,
        siteConfig.timeZone
      ).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      });

      if (time.isAsap) {
        timeDisplay = 'ASAP';
      }

      displayTimes.push(timeDisplay);
    });
  }

  return {
    pickupTimeDisplay,
    handleTimeSelection,
    times,
    displayTimes,
    isFetched
  };
};
