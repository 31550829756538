import { styled } from '@mui/material/styles';
import { ReactComponent as WarningIcon } from '../../assets/images/logo-icons/warning-circle.svg';

// Enum for styling future banner types
// Ex: Error, Success, etc
export enum BannerType {
  warning = 'warning'
}

export interface BannerProps {
  bannerText: string;
  bannerType?: BannerType;
  bannerSize?: string;
  isVisible?: boolean;
  hasRoundedCorners?: boolean;
}

const StyledBannerContainer = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'size' && prop !== 'visible'
})<{ size: string; visible: boolean }>(({ theme, size, visible }) => ({
  border: '1px solid',
  width: size,
  display: visible ? 'flex' : 'none',

  '&.banner-type-warning': {
    borderColor: theme.colors.warning[50],
    borderBottom: `1px solid ${theme.colors.warning[500]}`,
    background: theme.colors.warning[50],

    '&.has-rounded-corners': {
      borderColor: theme.colors.warning[500]
    }
  },

  '&.has-rounded-corners': {
    borderRadius: theme.spacing(2)
  }
}));

const StyledBanner = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: theme.spacing(3.5),

  '.banner-type-warning &': {
    color: theme.colors.warning[700]
  }
}));

const StyledWarningIconContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(2),
  display: 'flex',
  height: '24px',
  overflow: 'hidden'
}));

export default function Banner({
  bannerType,
  isVisible,
  bannerText,
  bannerSize,
  hasRoundedCorners
}: BannerProps) {
  return (
    <StyledBannerContainer
      data-testid="banner"
      className={`banner-type-${bannerType || BannerType.warning}
        ${hasRoundedCorners ? 'has-rounded-corners' : ''}`}
      size={bannerSize || '100%'}
      visible={isVisible ?? false}
    >
      <StyledBanner>
        <StyledWarningIconContainer>
          <WarningIcon />
        </StyledWarningIconContainer>
        {bannerText}
      </StyledBanner>
    </StyledBannerContainer>
  );
}
