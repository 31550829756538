import { useEffect } from 'react';
import { useAnalytics } from './useAnalytics';

export const useCountrySelectorObserver = () => {
  const analytics = useAnalytics();

  function sendCountryChange(event: MouseEvent) {
    if (!event.currentTarget) {
      return;
    }

    const target = event.currentTarget as HTMLLIElement;
    const span = target.querySelector(
      '.MuiTelInput-ListItemText-country span'
    ) as HTMLSpanElement;

    analytics('sms_country_code_selected', {
      country_name: span.textContent
    });
  }

  const attachListeners = () => {
    const el = document.getElementById('select-country');

    if (!el) {
      return;
    }

    const lis = el.getElementsByTagName('li');

    for (let i = lis.length - 1; i >= 0; i--) {
      lis[i].addEventListener('click', sendCountryChange);
    }
  };

  useEffect(() => {
    let observer: MutationObserver | null = null;

    if (document.getElementById('select-country')) {
      return attachListeners();
    }

    observer = new MutationObserver(attachListeners);
    const target = document.getElementsByTagName('body')[0];
    const config = { childList: true };
    observer.observe(target, config);

    return () => {
      if (!observer) {
        return;
      }

      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
