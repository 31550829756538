import { datadogLogs } from '@datadog/browser-logs';
import { getSession } from '../utils/Session/Session';

export enum LoggerEvent {
  ANALYTICS = 'analytics',
  APP_START = 'application.start',
  APP_ERROR = 'application.error',
  PROCESS_ERROR = 'application.checkout.process.error'
}

function error(
  event: string,
  message: string,
  context?: object,
  errorContext?: Error | undefined
): void {
  datadogLogs.logger.error(
    message,
    { ...context, application_event: event },
    errorContext
  );
}

function init() {
  datadogLogs.init({
    clientToken: 'pub4c12040d785dc0aa8b0414d1456d96c5',
    site: 'us5.datadoghq.com',
    sessionSampleRate: 100,
    env: window.DATADOG_ENV,
    version: window.VERSION || '',
    service: window.DATADOG_SERVICE,
    forwardConsoleLogs: 'all'
  });

  datadogLogs.setGlobalContextProperty('session_id', getSession());
}

function debug(
  event: string,
  message: string,
  context?: object,
  errorContext?: Error | undefined
): void {
  datadogLogs.logger.debug(
    message,
    { ...context, application_event: event },
    errorContext
  );
}

function info(
  event: string,
  message: string,
  context?: object,
  errorContext?: Error | undefined
): void {
  datadogLogs.logger.info(
    message,
    { ...context, application_event: event },
    errorContext
  );
}

function warn(
  event: string,
  message: string,
  context?: object,
  errorContext?: Error | undefined
): void {
  datadogLogs.logger.warn(
    message,
    { ...context, application_event: event },
    errorContext
  );
}

export default {
  init,
  error,
  info,
  debug,
  warn
};
