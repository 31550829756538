import StoreCard from '../StoreCard/StoreCard';
import { motion, useSpring } from 'framer-motion';
import { useEffect } from 'react';
import { StoreInterface } from '../../../../types/stores';
import { useLocation } from 'react-router-dom';

interface CardWrapperProps {
  index: number;
  store: StoreInterface;
}
export default function StoreCardWrapper(props: CardWrapperProps) {
  const y = useSpring(1000 + props.index * 1000, {
    stiffness: 1000,
    damping: 150
  });

  const location = useLocation();

  useEffect(() => {
    y.set(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (location.state?.skipAnimations) {
    return <StoreCard store={props.store} />;
  }

  return (
    <motion.div style={{ y }}>
      <StoreCard store={props.store} />
    </motion.div>
  );
}
