import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export interface RectangleDivProps {
  children?: React.ReactNode;
}

const Rectangle = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  position: 'relative',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: theme.spacing(2)
}));

const RectangleDiv: React.FC<RectangleDivProps> = (props) => {
  return <Rectangle data-testid="rectangle-div">{props.children}</Rectangle>;
};

export default RectangleDiv;
