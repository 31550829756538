import { createTheme } from '@mui/material/styles';
import { ColorConfig } from '../../types/fetch';

export const setupTheme = (colorConfig: ColorConfig) =>
  createTheme({
    colors: {
      base: {
        white: '#FFFFFF',
        black: '#1A1A1A',
        sky: '#CDCFD0',
        cinchio: '#5A8000'
      },
      gray: {
        25: '#FCFCFD',
        50: '#F9FAFB',
        100: '#F2F4F7',
        200: '#EAECF0',
        300: '#D0D5DD',
        400: '#98A2B3',
        450: '#666666',
        500: '#667085',
        600: '#475467',
        700: '#344054',
        800: '#1D2939',
        900: '#101828',
        checkbox: '#979797'
      },
      error: {
        25: '#FFFBFA',
        50: '#FEF3F2',
        100: '#FEE4E2',
        200: '#FECDCA',
        300: '#FDA29B',
        400: '#F97066',
        500: '#F04438',
        600: '#D92D20',
        700: '#B42318',
        800: '#912018',
        900: '#7A271A'
      },
      warning: {
        25: '#FFFCF5',
        50: '#FFFAEB',
        100: '#FEF0C7',
        200: '#FEDF89',
        300: '#FEC84B',
        400: '#FDB022',
        500: '#F79009',
        600: '#DC6803',
        700: '#B54708',
        800: '#93370D',
        900: '#7A2E0E'
      },
      success: {
        25: '#F6FEF9',
        50: '#ECFDF3',
        100: '#D1FADF',
        200: '#A6F4C5',
        300: '#6CE9A6',
        400: '#32D583',
        500: '#12B76A',
        600: '#039855',
        700: '#039855',
        800: '#039855',
        900: '#039855'
      },
      tutorial: {
        text: '#FFFFFF',
        primary: '#4264D1'
      },
      partner: {
        primary: colorConfig.brand.primary,
        50: colorConfig.brand[50],
        100: colorConfig.brand[100],
        200: colorConfig.brand[200],
        300: colorConfig.brand[300],
        400: colorConfig.brand[400],
        500: colorConfig.brand[500],
        600: colorConfig.brand[600],
        700: colorConfig.brand[700],
        800: colorConfig.brand[800],
        900: colorConfig.brand[900],
        button: {
          primary: {
            background: colorConfig.button.primary.background,
            font: colorConfig.button.primary.font,
            outline: colorConfig.button.primary.outline
          },
          secondary: {
            background: colorConfig.button.secondary.background,
            font: colorConfig.button.secondary.font,
            outline: colorConfig.button.secondary.outline
          }
        },
        font: {
          primary: colorConfig.font.primary
        }
      }
    },
    spacing: 4,
    typography: {
      fontFamily: 'Roboto',
      allVariants: {
        color: '#1A1A1A'
      },
      h1: {
        fontWeight: 500,
        fontSize: 96
      },
      h2: {
        fontWeight: 500,
        fontSize: 60
      },
      h3: {
        fontWeight: 600,
        fontSize: 48
      },
      h4: {
        fontWeight: 700,
        fontSize: 34
      },
      h5: {
        fontWeight: 600,
        fontSize: 24
      },
      h6: {
        fontWeight: 700,
        fontSize: 20
      },
      subtitle1: {
        fontWeight: 600,
        fontSize: 16
      },
      subtitle2: {
        fontWeight: 700,
        fontSize: 14
      },
      body1: {
        fontWeight: 400,
        fontSize: 16
      },
      body2: {
        fontWeight: 400,
        fontSize: 14
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 360,
        md: 768,
        lg: 1024,
        xl: 1536
      }
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.MuiTab-root': {
              color: '#667085',
              textTransform: 'capitalize',
              fontSize: '1rem'
            },
            '&.MuiTab-root:nth-of-type(1)': {
              marginLeft: '3.25rem'
            },
            '&.Mui-selected': {
              color: '#1A1A1A'
            }
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          region: {
            '& .MuiAccordionDetails-root': {
              marginTop: '-11px',
              marginBottom: '12px',
              paddingTop: 0
            }
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '.MuiSvgIcon-root': {
              width: '1.8rem',
              height: '1.8rem'
            }
          }
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.MuiRadio-root': {
              color: '#979797' // comes from theme.colors.gray.checkbox
            },
            '&.Mui-checked': {
              color: colorConfig.brand.primary
            }
          }
        }
      }
    }
  });
