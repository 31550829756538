import { useCallback, useEffect, useRef, useState } from 'react';
import appleInitialize from '../../../../utils/Payments/FreedomPay/appleInitialize';
import CircularProgress from '@mui/material/CircularProgress';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import fetchFPCalculate from '../../../../utils/Payments/FreedomPay/fetchFPCalculate';
import authorize, {
  AuthorizeBilling,
  AuthorizePayload
} from '../../../../utils/Payments/FreedomPay/authorize';
import extractSessionKey from '../../../../utils/Payments/FreedomPay/extractSessionKey';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { PricingOptions } from '../../../Cart/types';
import { OrderType } from '../../../../types/order';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../../types/GatewayResponse';
import extractNameFromDataString from '../../../../utils/Payments/FreedomPay/extractNameFromDataString';
import { GatewayError } from '../../GatewayError';
import { useQuery } from '@tanstack/react-query';
import ProcessingOrderDialog from '../../../Checkout/components/ProcessingOrderDialog/ProcessingOrderDialog';
import RetryOrderDialog from '../../../Checkout/components/RetryOrderDialog/RetryOrderDialog';
import { styled } from '@mui/material/styles';
import fetchNextOrderId from '../../../../utils/Payments/FreedomPay/fetchNextOrderId';

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.base.black,
  marginTop: theme.spacing(4)
}));

interface ApplePayProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderTimeStamp: string;
  transactionTotal: number;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const ApplePay = ({
  siteId,
  storeId,
  menuId,
  orderTimeStamp,
  transactionTotal,
  onSuccess,
  onError
}: ApplePayProps) => {
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  const { items: cartItems, priceToDisplay } = useCartV2();

  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const [showRetryDialog, setShowRetryDialog] = useState(false);

  const payload = {
    TotalPrice: transactionTotal.toFixed(2)
  };

  const {
    data: iframeData,
    isLoading: isLoadingIframe,
    remove: removeIframeQuery,
    refetch: reInitIFrame
  } = useQuery(
    [siteId, payload, 'apple_pay'],
    () => appleInitialize(siteId, payload),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );

  const createIframe = () => {
    return {
      __html: iframeData?.data.iFrame
    };
  };

  const onReceiveBillingData = useCallback(
    async (data: any) => {
      setPaymentProcessing(true);

      const addressData = JSON.parse(data.attributes[3].Value);

      const sessionKey = extractSessionKey(iframeData?.data.iFrame);

      try {
        const nextId = await fetchNextOrderId(storeId);

        const calculateObject = getCalculateObject(
          cartItems,
          priceToDisplay === PricingOptions.TAKEOUT
            ? OrderType.takeOut
            : OrderType.dineIn,
          null,
          menuId
        );

        const calculateResponse = await fetchFPCalculate(
          storeId,
          calculateObject
        );

        const billTo: AuthorizeBilling = {
          firstName: addressData.givenName,
          lastName: addressData.familyName,
          street1: addressData.addressLines[0] || '',
          street2: addressData.addressLines[1] || '',
          city: addressData.locality,
          state: addressData.administrativeArea,
          postalCode: addressData.postalCode,
          country: addressData.countryCode
        };

        const authorizePayload: AuthorizePayload = {
          PaymentKey: data.paymentKeys[0],
          PosSyncAttemptNumber: 1,
          orderTimeStamp: orderTimeStamp,
          nameOnCard: extractNameFromDataString(data.attributes[3].Value),
          invoiceNumber: nextId.toString(),
          chargeAmount: calculateResponse.chargeAmount.toString(),
          taxTotal: calculateResponse.taxTotal.toString(),
          items: calculateResponse.items,
          bearerSessionKey: sessionKey,
          channel: 'mobile',
          billTo: billTo
        };

        const authorizeResponse = await authorize(siteId, authorizePayload);

        if (authorizeResponse.decision === 'ACCEPT') {
          setShowRetryDialog(false);
          onSuccess({
            type: 'apple_pay',
            amount: transactionTotal,
            confirmationId: authorizeResponse.requestID,
            gateway: PaymentGatewayType.freedompay,
            paymentMethod: PaymentMethodType.ApplePay,
            orderId: nextId
          });

          window.FreedomPay.Apm.ApplePay.paymentSuccessful();
        } else {
          window.FreedomPay.Apm.ApplePay.paymentFailed();
          onError(
            new GatewayError(
              'unknown',
              PaymentMethodType.ApplePay,
              null,
              null,
              undefined
            )
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        window.FreedomPay.Apm.ApplePay.paymentFailed();

        if (error.response.status === 408) {
          // 408 === payment timeout
          setPaymentProcessing(false);
          setShowRetryDialog(true);
          return;
        }
        const gatewayError = error.response?.data || {};
        onError(
          new GatewayError(
            gatewayError.message,
            PaymentMethodType.ApplePay,
            null,
            null,
            gatewayError.errorCode
          )
        );
      }
    },
    [
      cartItems,
      onError,
      menuId,
      iframeData,
      onSuccess,
      orderTimeStamp,
      priceToDisplay,
      siteId,
      storeId,
      transactionTotal
    ]
  );

  useEffect(() => {
    const messageEventListener = async (e: MessageEvent) => {
      const message = e.data;
      const data = message.data;

      switch (message.type) {
        case 2:
          if (iframeContainerRef.current) {
            iframeContainerRef.current.firstElementChild?.setAttribute(
              'height',
              data.height
            );
          }
          break;
        case 3:
          await onReceiveBillingData(data);
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', messageEventListener);

    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  }, [onReceiveBillingData]);

  const onRetry = async () => {
    setShowRetryDialog(false);

    removeIframeQuery();
    await reInitIFrame();
  };

  if (isLoadingIframe) {
    return <StyledLoadingSpinner size={'1.8rem'} />;
  }

  return (
    <>
      {iframeData && iframeData.data && iframeData.data.iFrame !== '' ? (
        <div
          ref={iframeContainerRef}
          dangerouslySetInnerHTML={createIframe()}
        />
      ) : null}

      {paymentProcessing ? <ProcessingOrderDialog /> : null}
      {showRetryDialog ? <RetryOrderDialog onRetry={onRetry} /> : null}
    </>
  );
};

export default ApplePay;
