import { useQuery } from '@tanstack/react-query';
import { ORDER_STATUS_QUERY_KEY } from '../constants';
import { fetchOrderStatus } from '../services/orderService';

export const useOrderStatus = (publicOrderId: string, storeId: string) => {
  return useQuery(
    [ORDER_STATUS_QUERY_KEY, storeId],
    () => fetchOrderStatus(publicOrderId.toString(), storeId),
    {
      enabled: storeId !== '',
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000
    }
  );
};
