import axios from 'axios';
import { SPARKD_X_API_KEY } from '../config/apiAuthConfig';
import { BASE_URL } from '../config/backendServicesEndpoints';
import { getSession } from '../utils/Session/Session';
import logger from './logger';

export const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    'x-api-key': SPARKD_X_API_KEY,
    'x-request-id': getSession().id,
    'Content-Language': window.navigator.language
  }
});

http.interceptors.request.use(function (config) {
  const event =
    'api.' +
    config.url
      ?.replace(config.baseURL ?? '', '')
      .replace(/^(\/)+/, '')
      .replaceAll('/', '.') +
    '.request';

  logger.info(event, `Request | ${config.method} | ${config.url}`, {
    api: {
      request: {
        request_method: config.method?.toLowerCase(),
        url: config.url,
        header: config.headers,
        params: config.params,
        data: config.data
      }
    }
  });
  return config;
});

http.interceptors.response.use(
  function (response) {
    const event =
      'api.' +
      response.config.url
        ?.replace(response.config.baseURL ?? '', '')
        .replace(/^(\/)+/, '')
        .replaceAll('/', '.') +
      '.response';

    logger.info(
      event,
      `Response | ${response.config.method} | ${response.status} | ${response.config.url}`,
      {
        api: {
          request: {
            request_method: response.config.method?.toLowerCase(),
            url: response.config.url,
            headers: response.config.headers,
            params: response.config.params,
            data: response.config.data
          },
          response: {
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            data: response.data
          }
        }
      }
    );

    return response;
  },
  function (error) {
    if (!error.response) {
      throw error;
    }

    const event =
      'api.' +
      error.response.config.url
        ?.replace(error.response.config.baseURL ?? '', '')
        .replace(/^(\/)+/, '')
        .replaceAll('/', '.') +
      '.response';

    logger.error(
      event,
      `Response | ${error.response.config.method} | ${error.response.status} | ${error.response.config.url}`,
      {
        api: {
          request: {
            request_method: error.response.config.method?.toLowerCase(),
            url: error.response.config.url,
            headers: error.response.config.headers,
            params: error.response.config.params,
            data: error.response.config.data
          },
          response: {
            status: error.response.status,
            statusText: error.response.statusText,
            headers: error.response.headers,
            data: error.response.data
          }
        }
      }
    );

    throw error;
  }
);
