import { AxiosResponse } from 'axios';
import { PHONE_VALIDATION } from '../config/backendServicesEndpoints';
import { http } from './http';

export const postPhoneValidation = async (
  phoneNumber: string
): Promise<AxiosResponse<string>> => {
  return http
    .post(`${PHONE_VALIDATION}`, { phone: phoneNumber })
    .then((response) => {
      return response.data.isValid;
    });
};
