import { CartItemModifier } from '../../features/Cart/types';

export default function setCartModifiersForProcess(modifiers: {
  [index: string]: CartItemModifier;
}) {
  const mods = [];
  for (const key in modifiers) {
    mods.push(modifiers[key].productId);
  }
  return mods.sort((a, b) => a - b);
}
