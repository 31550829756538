import { backendConfig } from '../../../config/appConfig';
import { http } from '../../../services/http';

const fetchNextOrderId = async (storeId: string): Promise<number> => {
  const fetchOrderIdUrl = `${backendConfig.apiBaseUrl}/eat/order/store/${storeId}/id`;

  return http
    .post(fetchOrderIdUrl, { gateway: 'FreedomPay', channel: 'mobile' })
    .then((response) => response.data.id)
    .catch(() => null);
};

export default fetchNextOrderId;
