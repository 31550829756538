import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StoreHours from '../RestaurantHours';
import { StoreInterface } from '../../types/stores';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import AppRoute from '../../routing/AppRoute';
import { SyntheticEvent } from 'react';
import DietaryKey from '../DietaryKey';

const StyledInfoHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: theme.spacing(3.5)
}));

const StyledDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4)
}));

const StyledButtonDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  marginBottom: theme.spacing(4)
}));

const StyledButtonContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.colors.base.white,
  paddingBottom: theme.spacing(4)
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.partner.button.primary.background,
  color: theme.colors.partner.button.primary.font,
  outline: theme.colors.partner.button.primary.outline,
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background,
    color: theme.colors.partner.button.primary.font,
    outline: theme.colors.partner.button.primary.outline
  }
}));

interface StoreInfoDialogProps {
  open: boolean;
  handleClose: (event: SyntheticEvent) => void;
  store: StoreInterface;
}

const StoreInfoDialog = ({
  open,
  handleClose,
  store
}: StoreInfoDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleBrowseClick = (event: SyntheticEvent) => {
    const path = generatePath(AppRoute.MENU, {
      storeId: String(store.shortId)
    });

    if (pathname === path) {
      handleClose(event);
      return;
    }

    navigate(path);
  };

  const renderDietaryKey = () => {
    if (store.dietaryOptions && store.dietaryOptions.length) {
      return true;
    }
    if (store.allergens && store.allergens.length) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Dialog
        aria-labelledby="info-dialog-title"
        aria-describedby="info-dialog-content"
        open={open}
        scroll={'paper'}
        onClose={handleClose}
        transitionDuration={500}
        data-testid="info-dialog"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: '340px', // Set your width here
              padding: '16px',
              paddingBottom: '0'
            }
          },
          zIndex: 2000
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
        <StyledInfoHeaderContainer>
          <AccessTimeIcon style={{ width: '40px', height: '40px' }} />
        </StyledInfoHeaderContainer>

        <StoreHours store={store} />

        {renderDietaryKey() ? (
          <>
            <StyledDivider />
            <DietaryKey store={store} />
          </>
        ) : null}
        <StyledButtonContainer>
          <StyledButtonDivider />
          <StyledButton
            fullWidth
            onClick={handleBrowseClick}
            data-testid="ok-button"
          >
            {t('restaurant.main.browseMenu')}
          </StyledButton>
        </StyledButtonContainer>
      </Dialog>
    </>
  );
};

export default StoreInfoDialog;
