import { Payment } from '../../../../services/orderService';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import FormatPrice from '../../../Menu/components/FormatPrice/FormatPrice';
import { appConfig } from '../../../../config/appConfig';

export interface OrderPaymentProps {
  payment: Payment;
  total: number;
}

const OrderPaymentStyled = styled(Box)(({}) => ({
  backgroundColor: '#F7F7F8',
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  borderTop: '1px solid #D0D5DD'
}));

const HeaderStyled = styled('h3')(({ theme }) => ({
  fontSize: theme.spacing(5),
  padding: 0,
  margin: 0
}));

const SpanStyled = styled('span')(() => ({
  fontWeight: 'bold'
}));

const SectionStyled = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const InfoStyled = styled('div')(({ theme }) => ({
  borderTop: '1px solid #D0D5DD',
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(3)
}));

const LinkStyled = styled('a')(() => ({
  textDecoration: 'none',
  color: '#000000',
  fontWeight: 'bold'
}));

const OrderPayment = (props: OrderPaymentProps) => {
  const { t } = useTranslation();

  const supportEmail = appConfig.supportEmail;

  return (
    <OrderPaymentStyled>
      <HeaderStyled data-testid="payment-header">
        {t('restaurant.order.payment')}
      </HeaderStyled>
      <SectionStyled data-testid="payment-merchant">
        <span>{t('restaurant.order.merchant')}: </span>
        <SpanStyled>{props.payment.dBA}</SpanStyled>
      </SectionStyled>
      <SectionStyled data-testid="payment-total">
        <span>{t('restaurant.order.paid')}: </span>
        <SpanStyled>{FormatPrice(props.total)}</SpanStyled>
      </SectionStyled>
      <SectionStyled data-testid="payment-method">
        <span>{t('restaurant.order.method')}: </span>
        <SpanStyled>
          {props.payment.cardType} -- *{props.payment.lastFour}
        </SpanStyled>
      </SectionStyled>
      <InfoStyled data-testid="payment-info">
        <span>
          {t('restaurant.order.questions')}{' '}
          <LinkStyled href={'mailto:' + supportEmail}>
            {supportEmail}
          </LinkStyled>
        </span>
      </InfoStyled>
    </OrderPaymentStyled>
  );
};

export default OrderPayment;
