import { ChangeEvent, FC, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export interface MenuItemSpecialInstructionsProps {
  instructions: string;
  limit: number;
  title: string;
  onChange: (instructions: string) => void;
}

const StyledDropdown = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',

  borderTop: `1px solid ${theme.colors.gray[300]}`,
  paddingBottom: theme.spacing(16),
  '&.MuiPaper-root:last-of-type': {
    borderBottom: `1px solid ${theme.colors.gray[300]}`
  },
  '.MuiAccordionSummary-root': {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  },
  '.MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0)
  },
  '.MuiAccordionDetails-root': {
    marginBottom: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(0)
  },
  '.MuiPaper-elevation': {
    margin: theme.spacing(0)
  }
}));

const StyledDropdownTitleContainer = styled('div')({
  width: '100%'
});

const StyledDropdownTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600
}));

const StyledTextArea = styled('textarea')(({ theme }) => ({
  marginTop: theme.spacing(5),
  border: `1px solid ${theme.colors.gray[300]}`,
  borderRadius: theme.spacing(2),
  width: '100%',
  height: theme.spacing(40),
  padding: theme.spacing(4),
  fontSize: theme.spacing(4),
  fontFamily: 'Roboto'
}));

const StyledCounter = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'right',
  color: theme.colors.gray[500],
  fontSize: theme.spacing(3)
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.gray[300]}`,
  '&.MuiAccordionDetails-root': {
    paddingBottom: theme.spacing(4)
  }
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.gray[300]}`,
  '&.Mui-expanded': {
    borderBottom: 'none'
  }
}));

const MenuItemSpecialInstructions: FC<MenuItemSpecialInstructionsProps> = (
  props
) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleKeyUp = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newInstructions = event.currentTarget.value;
    if (
      newInstructions.length > props.limit &&
      newInstructions.length >= props.instructions.length
    ) {
      //Needed for pasting text
      props.onChange(newInstructions.substring(0, props.limit));
      return;
    }

    props.onChange(event.currentTarget.value);
  };

  return (
    <>
      <StyledDropdown
        expanded={open}
        onChange={() => setOpen(!open)}
        square
        disableGutters
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: '#1A1A1A' }} />}
          id={`${props.title}-si-header`}
          data-testid={`${props.title}-si-accordion`}
        >
          <StyledDropdownTitleContainer>
            <div>
              <StyledDropdownTitle data-testid="Special-Instructions-Title">
                {t('restaurant.menu.itemNotes')}
              </StyledDropdownTitle>
            </div>
          </StyledDropdownTitleContainer>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <StyledTextArea
            onChange={handleKeyUp}
            value={props.instructions}
            placeholder={t('restaurant.menu.itemNotesPlaceholder')}
          />
          <StyledCounter
            tabIndex={0}
            aria-label={`${props.instructions?.length}  characters entered out of  ${props.limit} available`}
          >
            {props.instructions?.length}/{props.limit}
          </StyledCounter>
        </StyledAccordionDetails>
      </StyledDropdown>
    </>
  );
};

export default MenuItemSpecialInstructions;
