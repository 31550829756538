import { FC } from 'react';
import ModifierItem from '../../../../components/ModifierItem/ModifierItem';
import './ModifierItems.scss';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CartItemModifier } from '../../../Cart/types';

interface ModifierItemProps {
  modifiers: { [index: string]: CartItemModifier };
}

const ModifierItemsStyled = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3)
}));

const ModifierItems: FC<ModifierItemProps> = (props) => {
  const modifiers = () => {
    const returnValue = [];

    for (const key in props.modifiers) {
      if (!props.modifiers.hasOwnProperty(key)) {
        continue;
      }

      returnValue.push(
        <ModifierItem
          className={'modifier-item'}
          modifier={props.modifiers[key]}
          key={key}
        />
      );
    }
    return returnValue;
  };

  return (
    <ModifierItemsStyled className={'modifier-items'}>
      {modifiers()}
    </ModifierItemsStyled>
  );
};

export default ModifierItems;
