import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { generatePath, useNavigate } from 'react-router-dom';
import AppRoute from '../../../../routing/AppRoute';
import { Player } from '@lottiefiles/react-lottie-player';

interface OrderHeaderProps {
  text: string;
  storedId: string;
}
const OrderHeaderContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(12.25)
}));

const CloseIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  paddingRight: theme.spacing(5.25),
  paddingTop: theme.spacing(5.25)
}));

const OrderHeaderContent = styled('div')(({}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));

const OrderHeaderAnimationWrapper = styled('div')(({ theme }) => ({
  height: theme.spacing(10),
  width: theme.spacing(10),
  position: 'relative'
}));

const OrderHeaderText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  paddingLeft: theme.spacing(6.94),
  paddingRight: theme.spacing(6.94),
  paddingTop: theme.spacing(5.33),
  paddingBottom: theme.spacing(10)
}));

const OrderHeader = ({ text, storedId }: OrderHeaderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(
      generatePath(AppRoute.MENU, {
        storeId: String(storedId)
      })
    );
  };

  const theme = useTheme();

  return (
    <OrderHeaderContainer data-testid="order-header">
      <CloseIconContainer>
        <CloseIcon
          tabIndex={0}
          onClick={handleClose}
          aria-hidden={false}
          focusable={true}
          data-testid="order-header-close"
        />
      </CloseIconContainer>
      <OrderHeaderContent>
        <OrderHeaderAnimationWrapper>
          <Player
            autoplay
            loop={false}
            src="/animations/ConfirmationSuccess.json"
            style={{
              height: theme.spacing(25),
              width: theme.spacing(25),
              position: 'absolute',
              left: '-75%',
              top: 0,
              bottom: 0,
              margin: 'auto 0'
            }}
            keepLastFrame={true}
          />
        </OrderHeaderAnimationWrapper>
        <OrderHeaderText variant="h6" data-testid="order-header-text">
          {text}
        </OrderHeaderText>
      </OrderHeaderContent>
    </OrderHeaderContainer>
  );
};

export default OrderHeader;
