import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../../../assets/images/logo-icons/icon-alert-health.svg';

const StyledContainer = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.gray[300]}`,
  borderTop: `1px solid ${theme.colors.gray[300]}`,
  padding: theme.spacing(4)
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'bold',
  marginBottom: theme.spacing(2)
}));

const StyledAlertIcon = styled(AlertIcon)(({ theme }) => ({
  width: theme.spacing(20),
  marginRight: theme.spacing(1)
}));

const StyledMessageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3)
}));

const StyledWarning = styled('span')(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 'bold'
}));

const StyledMessage = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5)
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.colors.base.black,
  fontSize: theme.spacing(3.5),
  fontWeight: 'bold'
}));

const CaliforniaProp65 = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledTitle>{t('restaurant.menu.proposition65Title')}</StyledTitle>
      <StyledMessageContainer>
        <StyledAlertIcon></StyledAlertIcon>
        <StyledMessage>
          <StyledWarning>
            {t('restaurant.menu.proposition65Warning') + ':' + ' '}
          </StyledWarning>
          {t('restaurant.menu.proposition65Message')}
          <StyledLink
            target="_blank"
            rel="noreferrer"
            href="https://www.P65Warnings.ca.gov/alcohol"
          >
            {t('restaurant.menu.proposition65Link')}
          </StyledLink>
        </StyledMessage>
      </StyledMessageContainer>
    </StyledContainer>
  );
};

export default CaliforniaProp65;
