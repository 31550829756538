import { CartItem, Modifier } from '../../../../services/orderService';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FormatPrice from '../../../Menu/components/FormatPrice/FormatPrice';

export interface OrderSummaryProps {
  items: CartItem[];
}

const OrderSummaryStyled = styled('div')(({ theme }) => ({
  padding: 16,
  marginBottom: theme.spacing(-6),
  fontWeight: 'bold'
}));

const HeaderStyled = styled('h2')(() => ({
  padding: 0,
  margin: 0
}));

const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'start',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(1),
  borderBottom: '1px solid #D0D5DD',
  paddingBottom: theme.spacing(2)
}));

const ItemStyled = styled('div')(() => ({
  flex: '1 1 0.5%',
  height: '100%'
}));

const PriceStyled = styled('div')(({ theme }) => ({
  flex: '1 1 0.5%',
  height: '100%',
  textAlign: 'right',
  marginRight: theme.spacing(1)
}));

const ItemNameStyled = styled('div')(() => ({
  flex: '3 1 70%',
  height: '100%'
}));

const ModifiersStyled = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  fontWeight: 'normal'
}));

const OrderSummary = (props: OrderSummaryProps) => {
  const { t } = useTranslation();

  const getModifiers = (modifiers: Modifier[]) => {
    return modifiers.map((item) => {
      let price = '';

      if (item.menuPrice > 0) {
        price = ` (${FormatPrice(item.menuPrice)})`;
      }
      return item.productName + price;
    });
  };

  return (
    <OrderSummaryStyled>
      <HeaderStyled data-testid="order-summary-header">
        {t('restaurant.order.summary')}
      </HeaderStyled>
      {props.items.map((item, index) => {
        return (
          <ItemContainer data-testid={`item-${index}`} key={`item-${index}`}>
            <ItemStyled data-testid={`item-${index}-count`}>
              {item.quantity}
            </ItemStyled>
            <ItemNameStyled>
              <div data-testid={`item-${index}-name`}>{item.productName}</div>
              {item.modifiers.length > 0 && (
                <ModifiersStyled data-testid={`item-${index}-modifiers`}>
                  {getModifiers(item.modifiers).join(' • ')}
                </ModifiersStyled>
              )}
            </ItemNameStyled>
            <PriceStyled data-testid={`item-${index}-price`}>
              {FormatPrice(item.itemTotalPrice)}
            </PriceStyled>
          </ItemContainer>
        );
      })}
    </OrderSummaryStyled>
  );
};

export default OrderSummary;
