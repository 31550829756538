import { FC } from 'react';
import DropdownMenu from './DropdownMenu/DropdownMenu';
import { MenuItem, MenuItemModGroup, ModItem } from '../../Menu/types';

interface MenuItemModifiersProps {
  item: MenuItem;
  selectedOptions: { [index: string]: ModItem } | undefined;
  addSelection: (itemModifier: ModItem) => void;
  removeSelection: (itemModifier: ModItem) => void;
  selectionError: boolean;
}

const MenuItemModifiers: FC<MenuItemModifiersProps> = (props) => {
  const getModifiers = (modifierGroups: MenuItemModGroup[]) => {
    if (!modifierGroups || modifierGroups.length <= 0) {
      return null;
    }
    return modifierGroups
      .sort((a, b) => b.name.localeCompare(a.name))
      .map((value, index) => {
        let required = false;
        if (value.style.toLowerCase() === 'radio') {
          required = true;
        }
        const initialState = value.initialState.toLowerCase() === 'open';
        return (
          <DropdownMenu
            required={required}
            title={value.name}
            selections={value}
            selectionError={props.selectionError}
            addSelection={props.addSelection}
            removeSelection={props.removeSelection}
            key={`modifiers_${index}`}
            selectedOptions={props.selectedOptions}
            menuItem={props.item}
            initialState={initialState}
          />
        );
      });
  };

  return (
    <div>
      <div>{getModifiers(props.item.modGroups)}</div>
    </div>
  );
};

export default MenuItemModifiers;
