import React from 'react';

export interface PlaceholderImageProps {
  height: string;
  width: string;
  label?: string;
  PlaceholderSVG: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

const PlaceholderImage: React.FC<PlaceholderImageProps> = ({
  height,
  width,
  label,
  PlaceholderSVG
}) => {
  return (
    <PlaceholderSVG
      height={height}
      width={width}
      aria-label={label}
      data-testid="placeholder-image"
    />
  );
};

export default PlaceholderImage;
