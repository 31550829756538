import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';
import { MenuItem } from '../../Menu/types';

interface MenuItemImageProps {
  item: MenuItem;
  screenWidth: number;
}

const ImageContainer = styled(Box, {
  shouldForwardProp: (prop: string) =>
    prop !== 'menuItemImageUrl' && prop !== 'isSuspended' && prop !== 'hasImage'
})<{
  menuItemImageUrl: string;
  alt: string;
  isSuspended: boolean;
  hasImage: boolean;
}>(
  ({ theme, menuItemImageUrl, isSuspended, hasImage }) => ({
    height: hasImage ? 210 : 0,
    ...(hasImage && {
      backgroundImage: `url(${menuItemImageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(3),
    marginBottom: theme.spacing(4),
    color: theme.colors.base.white,
    '&:after': isSuspended && hasImage
      ? {
          content: '" "',
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          width: '100%',
          height: 262,
          backgroundColor: theme.colors.base.black,
          opacity: isSuspended ? '0.5' : '1'
        }
      : ''
  })
);

const MenuItemImage: FC<MenuItemImageProps> = (props) => {
  const hasImage = props.item.imageUrl !== null && props.item.imageUrl.length > 0;
  // TODO: Localize alt text
  return (
    <ImageContainer
      menuItemImageUrl={props.item.imageUrl}
      isSuspended={props.item.isSuspended}
      alt={
        props.item.name 
          ? 'A picture of ' + props.item.name
         : 'A picture of the menu item'
        }
      hasImage={hasImage}
    />
  );
};

export default MenuItemImage;
