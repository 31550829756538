import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { Alert, Button, ButtonProps, Snackbar, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { utcToZonedTime } from 'date-fns-tz';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useCartV2 } from '../../hooks/useCartV2';

interface PickupTimeChangeProps {
  showDialog?: boolean;
  open: boolean;
  close: () => void;
}

const PickupTimeChange = (props: PickupTimeChangeProps) => {
  const StyledConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.colors.partner[500]
  }));

  const { t } = useTranslation();
  const theme = useTheme();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { pickupTime } = useCartV2();

  const displayTime = pickupTime
    ? utcToZonedTime(
        new Date(String(pickupTime)),
        siteConfig.timeZone
      ).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    : 'ASAP';

  if (props.showDialog) {
    return (
      <ConfirmationDialog
        open={props.open}
        title={t('error.noCapacity.timeMovedUp.title')}
        content={t('error.noCapacity.timeMovedUp.description', {
          time: displayTime
        })}
      >
        <StyledConfirmationButton
          onClick={props.close}
          data-testid="advanced-ordering-time-change-dialog-ok"
        >
          {t('restaurant.main.ok')}
        </StyledConfirmationButton>
      </ConfirmationDialog>
    );
  }

  return (
    <Snackbar open={props.open}>
      <Alert
        icon={false}
        color={undefined}
        data-testid="advanced-ordering-alert"
        sx={{
          color: '#fff',
          backgroundColor: '#000',
          borderRadius: '0.4rem',
          position: 'fixed',
          left: 0,
          bottom: theme.spacing(30),
          width: `calc(100% - ${theme.spacing(8)})`,
          marginLeft: theme.spacing(4),
          boxShadow: '0px 4px 10px #999'
        }}
        action={
          <Button
            data-testid="advanced-ordering-alert-ok"
            color="inherit"
            size="small"
            sx={{ textDecoration: 'underline' }}
            onClick={() => props.close()}
          >
            OK
          </Button>
        }
      >
        <span data-testid="advanced-ordering-alert-message">
          {t('restaurant.checkout.pickupTimesMoved', {
            time: displayTime
          })}
        </span>
      </Alert>
    </Snackbar>
  );
};

export default PickupTimeChange;
