import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Refresh from '@mui/icons-material/Refresh';

export interface ErrorMenuProps {
  onClick: () => void;
}

const ErrorMenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(18.75)
}));

const ErrorTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

const ErrorText = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 500,
  textAlign: 'center',
  margin: 0
}));

const RetryButton = styled(Button)(({ theme }) => ({
  color: theme.colors.partner.button.primary.font,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  backgroundColor: theme.colors.partner.button.primary.background,
  margin: 'auto',
  marginTop: theme.spacing(5.75),
  paddingLeft: theme.spacing(9),
  paddingRight: theme.spacing(9),
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background
  }
}));

const Icon = styled(Refresh)(({ theme }) => ({
  color: theme.colors.partner.button.primary.font
}));

const ErrorMenu: React.FC<ErrorMenuProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <ErrorMenuContainer data-testid="error-menu-container">
      <ErrorTextContainer>
        <ErrorText data-testid="error-text-1">
          {t('restaurant.menu.errorLine1')}
        </ErrorText>
        <ErrorText data-testid="error-text-2">
          {t('restaurant.menu.errorLine2')}
        </ErrorText>
      </ErrorTextContainer>
      <RetryButton disableRipple onClick={onClick} data-testid="retry-button">
        <Icon></Icon>
        {t('restaurant.menu.retry')}
      </RetryButton>
    </ErrorMenuContainer>
  );
};

export default ErrorMenu;
