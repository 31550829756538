import React from 'react';
import ReactDOM from 'react-dom/client';
// import i18n (needs to be bundled ;))
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import validateStorageCache from './utils/Session/validateStorageCache';
import logger, { LoggerEvent } from './services/logger';
import AppWrapper from './AppWrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FirebaseProvider } from './contexts/Firebase/FirebaseContext';
import { ErrorBoundary } from 'react-error-boundary';
import { Fallback } from './Fallback';

validateStorageCache();

logger.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();
logger.info(LoggerEvent.APP_START, 'Session Start');

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={Fallback}>
        <FirebaseProvider>
          <QueryClientProvider client={queryClient}>
            <AppWrapper />
          </QueryClientProvider>
        </FirebaseProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
