import { formatInTimeZone } from 'date-fns-tz';
import { isStoreAboutToClose } from '../Store/isStoreAboutToClose';

const isClosingBannerVisible = (
  userTime: Date,
  storeClosingTime: string,
  storeTimeZone: string
) => {
  // Returns user local time in store's timezone in 24-hour format
  const usersFormattedTime = formatInTimeZone(userTime, storeTimeZone, 'HH:mm');
  return isStoreAboutToClose(usersFormattedTime, storeClosingTime);
};

export default isClosingBannerVisible;
