import { SvgType } from '../../types/assets';
import mapAttributeStringToSVG from './mapAttributeStringToSVG';

const mapAllAttributes = (attributeStrings: string[]) => {
  const icons: SvgType[] = [];
  attributeStrings.forEach((attributeString: string) => {
    const svgReturn = mapAttributeStringToSVG(attributeString);
    if (svgReturn) {
      icons.push(svgReturn);
    }
  });

  return icons;
};

export default mapAllAttributes;
