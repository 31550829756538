import { Cart } from '../../../contexts/Cart/CartContextV2';
import { CartItem } from '../types';

export default function updateItem(state: Cart, item: CartItem, index: number) {
  return {
    ...state,
    items: state.items.map((current, currentIndex) => {
      if (current.productId !== item.productId) {
        return current;
      }

      if (current.productId === item.productId && currentIndex !== index) {
        return current;
      }

      return {
        ...current,
        ...item,
        quantity: item.quantity < 1 ? 1 : item.quantity
      };
    })
  };
}
