import { matchPath } from 'react-router-dom';
import getMatchedRoute from './getMatchedRoute';

export default function getMatchedParams() {
  const route = getMatchedRoute();
  if (!route) {
    return {};
  }

  const match = matchPath(route.path ?? '', window.location.pathname);
  return !match ? {} : match.params;
}
