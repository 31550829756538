import { Cart } from '../../../contexts/Cart/CartContextV2';
import { Tip } from '../../Cart/types';

export default function setTip(state: Cart, tip: Tip) {
  const tipObj: Tip = { type: '', value: 0 };
  if (tip.type === 'CUSTOM') {
    tipObj.type = 'dollar';
    tipObj.value = tip.value;
  }

  if (tip.type !== 'CUSTOM') {
    const converted = tip.type?.substring(0, tip.type.length - 1);
    tipObj.type = 'percentage';
    tipObj.value = Number(converted);
  }

  return {
    ...state,
    tip: tipObj
  };
}
