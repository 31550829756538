import { useCallback, useEffect, useRef, useState } from 'react';
import googleInitialize, {
  GoogleInitializePayload
} from '../../../../utils/Payments/FreedomPay/googleInitialize';
import CircularProgress from '@mui/material/CircularProgress';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import fetchFPCalculate from '../../../../utils/Payments/FreedomPay/fetchFPCalculate';
import { useCartV2 } from '../../../../hooks/useCartV2';
import authorize, {
  AuthorizeBilling,
  AuthorizePayload
} from '../../../../utils/Payments/FreedomPay/authorize';
import extractSessionKey from '../../../../utils/Payments/FreedomPay/extractSessionKey';
import { PricingOptions } from '../../../Cart/types';
import { OrderType } from '../../../../types/order';
import validate3DS from '../../../../utils/Payments/FreedomPay/validate3DS';
import constructValidatePayload from '../../../../utils/Payments/FreedomPay/constructValidatePayload';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../../types/GatewayResponse';
import { GatewayError } from '../../GatewayError';
import { getErrorDialogText } from '../../../../utils/Payments/Stripe/errors';
import fetchNextOrderId from '../../../../utils/Payments/FreedomPay/fetchNextOrderId';
import { useQuery } from '@tanstack/react-query';
import ProcessingOrderDialog from '../../../Checkout/components/ProcessingOrderDialog/ProcessingOrderDialog';
import RetryOrderDialog from '../../../Checkout/components/RetryOrderDialog/RetryOrderDialog';
import { styled } from '@mui/material/styles';
import { Country } from '../../../../types/countries';
import getCountryCallingCode from '../../../../utils/Payments/FreedomPay/getCountryCallingCode';

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.base.black,
  marginTop: theme.spacing(4)
}));

interface GooglePayProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderTimeStamp: string;
  transactionTotal: number;
  countries: Country[];
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const GooglePay = ({
  siteId,
  storeId,
  menuId,
  orderTimeStamp,
  transactionTotal,
  countries,
  onError,
  onSuccess
}: GooglePayProps) => {
  const { items: cartItems, priceToDisplay } = useCartV2();
  const iframeContainerRef = useRef<HTMLDivElement>(null);

  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const [showRetryDialog, setShowRetryDialog] = useState(false);

  const payment3dsData = useRef<unknown | null>(null);

  const payload: GoogleInitializePayload = {
    TotalPrice: transactionTotal.toFixed(2)
  };

  const {
    data: iframeData,
    isLoading: isLoadingIframe,
    remove: removeIframeQuery,
    refetch: reInitIFrame
  } = useQuery(
    [siteId, payload, 'google_pay'],
    () => googleInitialize(siteId, payload),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );

  const createIframe = () => {
    return {
      __html: iframeData?.data.iFrame
    };
  };

  const onReceivePaymentKeyAndBillingData = useCallback(
    async (data: any) => {
      if (iframeData?.data.iFrame === '' || !payment3dsData.current) {
        return;
      }

      setPaymentProcessing(true);

      const paymentKey = data.paymentKeys[0];
      const addressData = data.attributes[3].Value;
      const email = data.attributes[2].Value;

      const validate3dsResponse = await validate3DS(
        constructValidatePayload(payment3dsData.current)
      );

      if (!validate3dsResponse) {
        const errorResponse = getErrorDialogText('processing_error');

        //@todo: figure out what do we show if 3ds fails...
        onError(
          new GatewayError(
            'unknown',
            PaymentMethodType.GooglePay,
            errorResponse.title,
            errorResponse.description,
            undefined
          )
        );
        return;
      }

      const nameOnCard = addressData.name;

      const sessionKey = extractSessionKey(iframeData?.data.iFrame);

      try {
        const nextId = await fetchNextOrderId(storeId);
        const calculateObject = getCalculateObject(
          cartItems,
          priceToDisplay === PricingOptions.TAKEOUT
            ? OrderType.takeOut
            : OrderType.dineIn,
          null,
          menuId
        );

        const calculateResponse = await fetchFPCalculate(
          storeId,
          calculateObject
        );

        let phoneNumber: string = addressData.phoneNumber.toString();

        if (!phoneNumber.startsWith('+')) {
          phoneNumber = `+${getCountryCallingCode(
            addressData.countryCode,
            countries
          )}${phoneNumber}`;
        }

        const billTo: AuthorizeBilling = {
          firstName: addressData.name.split(' ')[0],
          lastName: addressData.name.split(' ')[1],
          street1: addressData.address1,
          street2: addressData.address2 || '',
          city: addressData.locality,
          state: addressData.administrativeArea,
          postalCode: addressData.postalCode,
          country: addressData.countryCode,
          phoneNumber: phoneNumber,
          email: email
        };

        const authorizePayload: AuthorizePayload = {
          PaymentKey: paymentKey,
          PosSyncAttemptNumber: 1,
          orderTimeStamp,
          nameOnCard,
          invoiceNumber: nextId.toString(),
          chargeAmount: calculateResponse.chargeAmount.toString(),
          taxTotal: calculateResponse.taxTotal.toString(),
          items: calculateResponse.items,
          bearerSessionKey: sessionKey,
          channel: 'mobile',
          billTo: billTo
        };

        const authorizeResponse = await authorize(siteId, authorizePayload);

        if (authorizeResponse.decision === 'ACCEPT') {
          setShowRetryDialog(false);

          payment3dsData.current = null;

          onSuccess({
            type: 'credit_card',
            amount: transactionTotal,
            confirmationId: authorizeResponse.requestID,
            gateway: PaymentGatewayType.freedompay,
            paymentMethod: PaymentMethodType.GooglePay,
            orderId: nextId
          });
        } else {
          const errorResponse = getErrorDialogText('processing_error');
          onError(
            new GatewayError(
              'declined',
              PaymentMethodType.GooglePay,
              errorResponse.title,
              errorResponse.description,
              undefined
            )
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.response?.status === 408) {
          // 408 === payment timeout
          setPaymentProcessing(false);
          setShowRetryDialog(true);
          return;
        }

        const gatewayError = err.response?.data || {};
        onError(
          new GatewayError(
            gatewayError.message,
            PaymentMethodType.GooglePay,
            null,
            null,
            gatewayError.errorCode
          )
        );
      }
    },
    [
      cartItems,
      iframeData,
      menuId,
      onError,
      onSuccess,
      orderTimeStamp,
      priceToDisplay,
      siteId,
      storeId,
      transactionTotal
    ]
  );

  const onReceive3dsData = useCallback((data: unknown) => {
    payment3dsData.current = data;
  }, []);

  useEffect(() => {
    const messageEventListener = async (e: MessageEvent) => {
      const message = e.data;
      const data = message.data;

      switch (message.type) {
        case 3: // event listener for getting payment key from iframe
          onReceivePaymentKeyAndBillingData(data);

          break;

        // 3ds data fires first, so we need to keep it temporarily
        case 14: // 14 and 16 are both for getting 3DS parameters for security validation
        case 16:
          onReceive3dsData(data);
          break;

        default:
          break;
      }
    };

    window.addEventListener('message', messageEventListener);

    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  }, [onReceivePaymentKeyAndBillingData, onReceive3dsData, iframeData]);

  const onRetry = async () => {
    payment3dsData.current = null;

    setShowRetryDialog(false);

    removeIframeQuery();
    await reInitIFrame();
  };

  if (isLoadingIframe) {
    return <StyledLoadingSpinner size={'1.8rem'} />;
  }

  return (
    <>
      {iframeData?.data.iFrame && iframeData.data.iFrame !== '' ? (
        <div
          ref={iframeContainerRef}
          style={{ height: '60px' }}
          dangerouslySetInnerHTML={createIframe()}
        />
      ) : null}

      {paymentProcessing ? <ProcessingOrderDialog /> : null}
      {showRetryDialog ? <RetryOrderDialog onRetry={onRetry} /> : null}
    </>
  );
};

export default GooglePay;
