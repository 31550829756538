import { useQuery } from '@tanstack/react-query';
import { Times } from '../utils/API/processPickupTimeResponse';
import { useSiteConfig } from './useSiteConfig';
import { useAnalytics } from './useAnalytics';
import { utcToZonedTime } from 'date-fns-tz';
import { getPickupTimes } from '../utils/API/getPickupTimes';

export const usePickupTimes = (storeId: string) => {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const analytics = useAnalytics();

  const logTimeDiff = (firstAvailable: Times) => {
    const nowLocalTimezone = utcToZonedTime(new Date(), siteConfig.timeZone);
    const firstAvailableTimezone = utcToZonedTime(
      firstAvailable.date,
      siteConfig.timeZone
    );

    analytics('throttled_order', {
      throttled_delay_amount:
        firstAvailableTimezone.getTime() - nowLocalTimezone.getTime()
    });
  };

  return useQuery(
    ['order_pickup_times', storeId],
    async () => {
      const times = await getPickupTimes(storeId, siteConfig.timeZone);
      if (times[0] && !times[0].isAsap) {
        logTimeDiff(times[0]);
      }

      return times;
    },
    {
      enabled: storeId !== '',
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 0,
      useErrorBoundary: (error: { response: { status?: number } }) =>
        error.response?.status !== 200
    }
  );
};
