import { useParams } from 'react-router-dom';
import { CartProviderV2 } from '../../contexts/Cart/CartContextV2';
import MenuItemPage from './MenuItemPage';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useStore } from '../../hooks/useStore';
import { useColorConfig } from '../../hooks/useColorConfig';
import AppLayout from '../../layouts/AppLayout';
import { ThemeProvider } from '@mui/material';

const MenuItem = () => {
  const { storeId: shortId } = useParams();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const theme = useColorConfig();

  if (!shortId || !store) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <CartProviderV2 shortId={shortId}>
          <MenuItemPage store={store} />
        </CartProviderV2>
      </AppLayout>
    </ThemeProvider>
  );
};

export default MenuItem;
