import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BotStatus } from '../../../Cart/types';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { usePhoneCountries } from '../../../../hooks/usePhoneCountries';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import axios, { AxiosError } from 'axios';
import { FREEDOM_PAY } from '../../../../config/backendServicesEndpoints';
import { backendConfig } from '../../../../config/appConfig';
import validateBillingInfo from '../../../../utils/Payments/FreedomPay/validateBillingInfo';
import { Country } from '../../../../types/countries';

export interface BillingFormValues {
  BillingFirstName: string;
  BillingLastName: string;
  Email: string;
  MobilePhone: string;
  BillingAddress1: string;
  BillingCity: string;
  BillingPostalCode: string;
  BillingCountryCode: number;
  Country: Country | null;
}

interface CollectBillingProps {
  billingOnSubmit: (formValues: BillingFormValues) => void;
  initialValues: BillingFormValues | null;
  countries: Country[];
  handleCancelClick: () => void;
}

const StyledFormContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  width: '100%'
}));

const Title = styled(Typography)(({ theme }) => ({
  variant: 'body1',
  marginBottom: theme.spacing(4),
  fontWeight: 600
}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
  '& input::placeholder': {
    color: '#667085',
    opacity: 1
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  '.MuiOutlinedInput-input': {
    padding: '11px 16px'
  }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1)
}));

const StyledDropdown = styled(Select)(({ theme }) => ({
  marginBottom: theme.spacing(0)
}));

const StyledCountryErrorText = styled(FormHelperText)(({ theme }) => ({
  marginBottom: theme.spacing(5)
}));

const ContinueButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.base.black,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base.black
  },
  boxShadow: 'none',
  color: theme.colors.base.white,
  fontSize: theme.spacing(4),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  padding: 0,
  width: '100%'
}));

const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.base.white,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base.white
  },
  boxShadow: 'none',
  border: `1px solid ${theme.colors.base.black}`,
  color: theme.colors.base.black,
  fontSize: theme.spacing(4),
  height: theme.spacing(12),
  marginRight: theme.spacing(4),
  padding: 0,
  width: '100%'
}));

const ButtonFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(4)
}));

const StyledPhoneInputContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  button: {
    borderRadius: 0,
    height: theme.spacing(9),
    width: theme.spacing(15),
    paddingTop: 0,
    paddingBottom: 0,
    picture: {
      '&::after': {
        paddingLeft: theme.spacing(1),
        content: 'url("/expand_more_black_24dp.svg")',
        paddingTop: theme.spacing(1)
      }
    }
  },
  input: {
    paddingLeft: theme.spacing(4)
  }
}));

const StyledTelephoneInput = styled(MuiTelInput)(({ theme }) => ({
  '.MuiTypography-root': {
    marginLeft: theme.spacing(2)
  }
}));

const CollectBilling = ({
  billingOnSubmit,
  initialValues,
  countries,
  handleCancelClick
}: CollectBillingProps) => {
  const { t } = useTranslation();
  const { isBot } = useCartV2();
  const phoneCountries = usePhoneCountries();
  const [mobilePhone, setMobilePhone] = useState(initialValues?.MobilePhone);

  let localeLanguage =
    window.navigator.language.split('-').length > 1
      ? (window.navigator.language
          .split('-')[1]
          .toUpperCase() as MuiTelInputCountry)
      : (window.navigator.language.toUpperCase() as MuiTelInputCountry);

  if (
    phoneCountries.data &&
    phoneCountries.data.indexOf(localeLanguage) === -1
  ) {
    localeLanguage = 'US';
  }
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    register,
    setError,
    formState: { errors }
  } = useForm<BillingFormValues>({
    defaultValues:
      initialValues && initialValues.Country
        ? initialValues
        : {
            Country: {
              name: 'United States of America',
              alpha2: 'US',
              num3: 840,
              callingCode: '+1'
            }
          }
  });

  const onHandleFormChange = (
    event: ChangeEvent,
    field: keyof BillingFormValues
  ) => {
    const inputValue = (event.target as HTMLInputElement).value;
    clearErrors(field);
    setValue(field, inputValue);
  };

  const handleValidate = async (data: BillingFormValues) => {
    await validateBillingInfo(data)
      .then(() => {
        billingOnSubmit(data);
      })
      .catch((err: AxiosError<{ errors: Record<string, string>[] }>) => {
        if (err.response?.status === 400) {
          err.response.data.errors.forEach((error) => {
            const key = Object.keys(error)[0] as keyof BillingFormValues;
            const message = Object.values(error)[0];

            setError(key, { type: 'manual', message });
          });
        }
      });
  };

  const handleOnKeyDown = () => {
    clearErrors('MobilePhone');
  };

  const handlePhoneChange = (newPhone: string) => {
    setMobilePhone(newPhone);
    setValue('MobilePhone', newPhone);
  };

  const onHandleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof BillingFormValues
  ) => {
    let inputValue = event.target.value;

    // Reject numbers by removing any digits from the input
    inputValue = inputValue.replace(/\d+/g, '');

    clearErrors(field);
    setValue(field, inputValue);
  };

  return (
    <StyledFormContainer data-testid="collect-billing">
      <Title>{t('restaurant.checkout.billingInformation')}</Title>
      <form onSubmit={handleSubmit(handleValidate)} id="billing-information">
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.firstName')}
        </InputLabel>
        <Controller
          name="BillingFirstName"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.firstName') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterFirstName')}
              fullWidth
              error={!!errors.BillingFirstName}
              onChange={(e) => onHandleNameChange(e, 'BillingFirstName')}
              helperText={errors.BillingFirstName?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.lastName')}
        </InputLabel>
        <Controller
          name="BillingLastName"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.lastName') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterLastName')}
              fullWidth
              error={!!errors.BillingLastName}
              onChange={(e) => onHandleNameChange(e, 'BillingLastName')}
              helperText={errors.BillingLastName?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.email')}
        </InputLabel>
        <Controller
          name="Email"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.email') +
              ' ' +
              t('restaurant.checkout.isRequired'),
            pattern: /^\S+@\S+$/i
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterEmail')}
              fullWidth
              error={!!errors.Email}
              onChange={(e) => onHandleFormChange(e, 'Email')}
              helperText={errors.Email?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.mobilePhone')}
        </InputLabel>
        <StyledPhoneInputContainer>
          {phoneCountries.data && phoneCountries.data.length > 0 ? (
            <Controller
              name="MobilePhone"
              control={control}
              rules={{
                required: t('restaurant.checkout.emptyPhoneError')
              }}
              render={({ field }) => (
                <StyledTelephoneInput
                  {...field}
                  ref={register('MobilePhone').ref}
                  helperText={errors.MobilePhone?.message}
                  error={!!errors.MobilePhone}
                  value={mobilePhone}
                  onChange={handlePhoneChange}
                  onKeyDown={() => handleOnKeyDown()}
                  forceCallingCode
                  fullWidth
                  required
                  focusOnSelectCountry
                  onlyCountries={
                    phoneCountries.data && phoneCountries.data.length
                      ? phoneCountries.data
                      : [localeLanguage]
                  }
                  defaultCountry={localeLanguage}
                  preferredCountries={[localeLanguage]}
                  id="phone-number"
                  data-testid="phone-number"
                  placeholder={t('restaurant.checkout.mobilePhone')}
                />
              )}
            />
          ) : null}
        </StyledPhoneInputContainer>
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.streetAddress')}
        </InputLabel>
        <Controller
          name="BillingAddress1"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.streetAddress') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterAddress')}
              fullWidth
              error={!!errors.BillingAddress1}
              onChange={(e) => onHandleFormChange(e, 'BillingAddress1')}
              helperText={errors.BillingAddress1?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.city')}
        </InputLabel>
        <Controller
          name="BillingCity"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.city') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterCity')}
              fullWidth
              error={!!errors.BillingCity}
              onChange={(e) => onHandleFormChange(e, 'BillingCity')}
              helperText={errors.BillingCity?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.postalCode')}
        </InputLabel>
        <Controller
          name="BillingPostalCode"
          control={control}
          rules={{
            required:
              t('restaurant.checkout.postalCode') +
              ' ' +
              t('restaurant.checkout.isRequired')
          }}
          render={({ field }) => (
            <InputField
              {...field}
              placeholder={t('restaurant.checkout.enterPostalCode')}
              fullWidth
              error={!!errors.BillingPostalCode}
              onChange={(e) => onHandleFormChange(e, 'BillingPostalCode')}
              helperText={errors.BillingPostalCode?.message}
            />
          )}
        />
        <InputLabel variant="subtitle1">
          {t('restaurant.checkout.country')}
        </InputLabel>
        {countries && countries.length > 0 ? (
          <FormControl fullWidth error={!!errors.Country}>
            <Controller
              name="Country"
              control={control}
              rules={{
                required: `${t('restaurant.checkout.country')} ${t(
                  'restaurant.checkout.isRequired'
                )}`
              }}
              render={({ field }) => (
                <>
                  <StyledDropdown
                    {...field}
                    MenuProps={{
                      style: {
                        zIndex: 1500,
                        marginBottom: '200px',
                        marginTop: '-80px'
                      }
                    }}
                    displayEmpty
                    onChange={(event) => {
                      const selectedCountry = countries.find(
                        (country) => country.name === event.target.value
                      );
                      field.onChange(selectedCountry || null);
                    }}
                    value={field.value?.name || ''}
                  >
                    <MenuItem value="" disabled>
                      {t('restaurant.checkout.selectCountry')}
                    </MenuItem>
                    {countries.map((country: Country, index: number) => (
                      <MenuItem key={index} value={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </StyledDropdown>
                  <StyledCountryErrorText>
                    {errors.Country?.message}
                  </StyledCountryErrorText>
                </>
              )}
            />
          </FormControl>
        ) : null}
        {initialValues !== null ? (
          <ButtonFooter>
            <CancelButton onClick={handleCancelClick}>
              {t('restaurant.main.cancel')}
            </CancelButton>
            <ContinueButton form="billing-information" type="submit">
              {t('restaurant.checkout.update')}
            </ContinueButton>
          </ButtonFooter>
        ) : (
          <ContinueButton
            variant="contained"
            disableRipple
            type="submit"
            data-testid="continue-to-card-info"
            disabled={isBot === BotStatus.IS_BOT}
          >
            {t('restaurant.checkout.continueToCardInfo')}
          </ContinueButton>
        )}
      </form>
    </StyledFormContainer>
  );
};

export default CollectBilling;
