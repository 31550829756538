import { styled } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';

const StyledClosed = styled(Chip)<ChipProps>(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.colors.base.black,
  color: theme.colors.base.white,
  fontSize: theme.spacing(5),
  fontWeight: 500
}));

interface StatusLabelProps {
  labelText: string;
}

export default function StatusLabel(props: StatusLabelProps) {
  return <StyledClosed label={props.labelText}></StyledClosed>;
}
