import { fetchSiteConfig } from '../../services/configService';
import getSiteConfigFromSession from '../Session/getSiteConfigFromSession';
import { emptyConfig } from '../../models/emptyConfig';
import { SessionKeys } from '../Session/Session';

export const getSiteConfig = async (siteCode: string) => {
  if (siteCode === '') {
    return emptyConfig;
  }

  const cached = getSiteConfigFromSession();

  if (cached && cached[siteCode]) {
    return cached[siteCode];
  }

  cached[siteCode] = await fetchSiteConfig(siteCode);

  window.sessionStorage.setItem(
    SessionKeys.SITE_CONFIG_SESSION_KEY,
    JSON.stringify(cached)
  );

  return cached[siteCode];
};
