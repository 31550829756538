import { GatewayProp } from '../Gateways/types/GatewayProp';
import Stripe from '../Gateways/Stripe/Stripe';
import {
  GatewayResponse,
  PaymentMethodType
} from '../Gateways/types/GatewayResponse';
import {
  PaymentGateway,
  PaymentGatewayType,
  PostOrder,
  ProcessOrderResponse
} from '../../types/fetch';
import createPostOrderObject from '../../utils/API/createPostOrderObject';
import { useCartV2 } from '../../hooks/useCartV2';
import { postOrderProcessing } from '../../services/orderService';
import processOrderProcResponse from '../../utils/API/processOrderProcResponse';
import { GatewayError, GatewayMessage } from '../Gateways/GatewayError';
import CardPointeForm from '../Gateways/CardPointe';
import FreedomPay from '../Gateways/FreedomPay/FreedomPay';
import fetchOrderId from '../../utils/Payments/FreedomPay/fetchOrderId';
import { PricingOptions } from '../Cart/types';
import { OrderType } from '../../types/order';
import { useEffect, useState } from 'react';
import getXTest from '../../utils/Payments/getXTest';

export interface PaymentFormProps extends Omit<GatewayProp, 'onSuccess'> {
  siteId: string;
  storeId: string;
  storeName: string;
  menuId: number;
  onSuccess: (publicOrderId: string, paymentMethod: PaymentMethodType) => void;
  handleClose: () => void;
  paymentGateway: PaymentGateway;
  orderTimeStamp: string;
  sessionTimeout: () => void;
}

const PaymentForm = (props: PaymentFormProps) => {
  const {
    items: cartItems,
    pickupTime,
    promoCode,
    hasRoundUp,
    priceToDisplay
  } = useCartV2();

  const onSuccess = async (gatewayResponse: GatewayResponse) => {
    const postOrderBody: PostOrder = createPostOrderObject(
      cartItems,
      pickupTime,
      hasRoundUp,
      props.cartCalculations.data.summary.tip,
      props.userInfo,
      promoCode,
      props.menuId,
      priceToDisplay === PricingOptions.TAKEOUT
        ? OrderType.takeOut
        : OrderType.dineIn,
      gatewayResponse.orderId
    );

    const postOrderProcessBody: PostOrder = {
      payment: gatewayResponse,
      ...postOrderBody
    };

    postOrderProcessing(
      postOrderProcessBody,
      props.storeId,
      getXTest(props.paymentGateway.paymentGateway)
    )
      .then((processingResponse) => {
        const { orderProcessResponse, publicOrderId } =
          processOrderProcResponse(
            processingResponse as unknown as ProcessOrderResponse
          );

        if (orderProcessResponse.toLocaleUpperCase() === 'SUCCESS') {
          props.onSuccess(publicOrderId, gatewayResponse.paymentMethod);
          return;
        }

        throw new GatewayError(
          'ignite_process_error',
          gatewayResponse.paymentMethod,
          null,
          null,
          {
            response: processingResponse,
            request: postOrderProcessBody
          }
        );
      })
      .catch((error) => {
        let message: GatewayMessage = 'ignite_process_error';

        if (
          error.response &&
          error.response.data &&
          error.response.data.errorCode &&
          (error.response.data.errorCode === 'NO_CAPACITY' ||
            error.response.data.errorCode === 'INVALID_ADV_ORDER' ||
            error.response.data.errorCode === 'BAD_CART_ITEM_SUSPENDED')
        ) {
          message = error.response.data.errorCode.toLowerCase();
        }

        props.onError(
          new GatewayError(message, gatewayResponse.paymentMethod, null, null, {
            error: error,
            response: error.response,
            request: postOrderProcessBody
          })
        );
      });
  };

  let paymentForm = null;

  switch (props.paymentGateway.paymentGateway) {
    case PaymentGatewayType.Stripe:
      paymentForm = (
        <Stripe
          userInfo={props.userInfo}
          cartCalculations={props.cartCalculations}
          onSubmit={props.onSubmit}
          onSuccess={onSuccess}
          onError={props.onError}
        />
      );
      break;
    case PaymentGatewayType.CardPointe:
      paymentForm = (
        <CardPointeForm
          amount={props.cartCalculations.data.summary.total}
          paymentGateway={props.paymentGateway}
          storeName={props.storeName}
          onSubmit={props.onSubmit}
          onSuccess={onSuccess}
          onError={props.onError}
          handleClose={props.handleClose}
        />
      );
      break;
    case PaymentGatewayType.FreedomPay:
      paymentForm = (
        <FreedomPay
          transactionTotal={props.cartCalculations.data.summary.total}
          handleClose={props.handleClose}
          menuId={props.menuId}
          siteId={props.siteId}
          storeId={props.storeId}
          orderTimeStamp={props.orderTimeStamp}
          sessionTimeout={props.sessionTimeout}
          onError={props.onError}
          onSuccess={onSuccess}
        />
      );
      break;
    default:
      paymentForm = (
        <Stripe
          userInfo={props.userInfo}
          cartCalculations={props.cartCalculations}
          onSubmit={props.onSubmit}
          onSuccess={onSuccess}
          onError={props.onError}
        />
      );
  }

  return <>{paymentForm}</>;
};

export default PaymentForm;
