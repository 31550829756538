import { styled } from '@mui/material/styles';

import Chip, { ChipProps } from '@mui/material/Chip';

const BadgeUnavailable = styled(Chip)<ChipProps>(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.colors.base.black,
  borderColor: theme.colors.base.black,
  color: theme.colors.base.white,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: 700,
  height: theme.spacing(6)
}));

export default BadgeUnavailable;
