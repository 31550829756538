import { FC } from 'react';
import RadioGroup from '../RadioGroup/RadioGroup';
import CheckBoxGroup from '../CheckBoxGroup/CheckBoxGroup';
import { MenuItemModGroup, ModItem } from '../../../Menu/types';

interface ItemModifierGroupProps {
  modName: string;
  group: MenuItemModGroup;
  selectedGroupItems: { [index: string]: ModItem };
  addSelection: (itemModifier: ModItem) => void;
  removeSelection: (itemModifier: ModItem) => void;
}

const ItemModifierGroup: FC<ItemModifierGroupProps> = (props) => {
  const { modName, group, selectedGroupItems, addSelection, removeSelection } =
    props;

  if (group.style.toLowerCase() === 'radio') {
    return (
      <RadioGroup
        modName={modName}
        group={group}
        selectedGroupItems={selectedGroupItems}
        addSelection={addSelection}
        removeSelection={removeSelection}
      />
    );
  }

  return (
    <CheckBoxGroup
      modName={modName}
      group={group}
      selectedGroupItems={selectedGroupItems}
      addSelection={addSelection}
      removeSelection={removeSelection}
    />
  );
};

export default ItemModifierGroup;
