import List, { ListProps } from '@mui/material/List';
import { ThemeProvider, styled } from '@mui/material/styles';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import useStores from '../../hooks/useStores';
import StoreCardWrapper from './components/StoreCardWrapper/StoreCardWrapper';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useColorConfig } from '../../hooks/useColorConfig';
import AppLayout from '../../layouts/AppLayout';

const StyledContainer = styled(List)<ListProps>(({ theme }) => ({
  marginTop: theme.spacing(13),
  paddingBottom: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
}));

export default function Stores() {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { state } = useLocation();
  const { data: stores, isLoading } = useStores(siteConfig.partnerId);
  const theme = useColorConfig();

  if (isLoading) {
    return null;
  }

  if (stores && stores.length === 0) {
    throw new Error('Missing Store');
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <StyledContainer role="presentation">
          <Helmet>
            <title>
              {state?.title ? `${state?.title} Stores Page` : 'Stores Page'}
            </title>
          </Helmet>
          {stores?.map((restaurant, index) => (
            <StoreCardWrapper
              index={index}
              store={restaurant}
              key={`restaurant-wrapper-${index}`}
            />
          ))}
        </StyledContainer>
      </AppLayout>
    </ThemeProvider>
  );
}
