import { useQuery } from '@tanstack/react-query';
import { fetchStore } from '../services/stores';
const STORE_KEY_QUERY = 'store';

export const useStore = (siteId: string, shortId: string) => {
  return useQuery(
    [STORE_KEY_QUERY, shortId],
    () => fetchStore(siteId, shortId),
    {
      enabled: !!shortId,
      staleTime: 5 * 60 * 1000,
      retry: false
    }
  );
};
