enum AppRoute {
  HOME = '/',
  STORES = '/p/:siteId',
  MENU = '/r/:storeId',
  MENU_ITEM = '/r/:storeId/item/:itemId',
  MENU_ITEM_EDIT = '/r/:storeId/item/:itemId/edit/:editItemIndex',
  ORDER_STATUS = '/r/:storeId/status',
  CART = '/r/:storeId/cart',
  CHECKOUT = '/r/:storeId/checkout',
  CLOSED = '/r/closed/:storeId',
  CLOSED_ALT = '/p/:siteId/r/closed/:storeId',
  VERSION = '/version',
  SITE_NOT_FOUND = '/p/:siteId/*',
  STORE_NOT_FOUND = '/r/:storeId/*'
}

export default AppRoute;
