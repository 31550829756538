import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PrepWindowContainer = styled('div')(({}) => ({
  display: 'flex'
}));

const PrepWindowText = styled(Typography, {
  shouldForwardProp: (prop: string) => prop !== 'color'
})<{ color: string; fontSize: string }>(({ color, fontSize }) => ({
  color: color,
  fontSize
}));

interface FoodPrepWindowProps {
  text: string;
  color: string;
  fontSize?: string;
}

const FoodPrepWindow = ({
  text,
  color,
  fontSize = '16px'
}: FoodPrepWindowProps) => {
  return (
    <>
      <PrepWindowContainer>
        <PrepWindowText
          variant="body2"
          color={color}
          fontSize={fontSize}
          data-testid="food-prep-window-text"
        >
          {text}
        </PrepWindowText>
      </PrepWindowContainer>
    </>
  );
};

export default FoodPrepWindow;
