import { parse, subMinutes } from 'date-fns';
import { StoreShiftInterface } from '../../types/stores';
import { zonedTimeToUtc } from 'date-fns-tz';

export const isStoreOpen = (
  shifts: StoreShiftInterface[],
  timezone: string
) => {
  const now = new Date();

  if (!shifts) {
    return false;
  }

  return shifts.some((shift) => {
    if (!shift || !shift.endTime || !shift.startTime) {
      return false;
    }

    const shiftStartTime = zonedTimeToUtc(
      parse(shift.startTime, 'HH:mm', now),
      timezone
    );

    // end time needs to 10 minutes before
    const shiftEndTime = subMinutes(
      zonedTimeToUtc(parse(shift.endTime, 'HH:mm', now), timezone),
      10
    );

    return now >= shiftStartTime && now <= shiftEndTime;
  });
};
