import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { CartProviderV2 } from '../../contexts/Cart/CartContextV2';
import CartContent from './CartContent';
import { useStore } from '../../hooks/useStore';
import useStoreClosedCheck from '../../hooks/useStoreClosedCheck';
import PickupTimeAlert from '../../components/PickupTimeAlert';
import OrderingUnavailableDialog from '../../components/OrderingUnavailableDialog/OrderingUnavailableDialog';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import AppLayout from '../../layouts/AppLayout';
import { useColorConfig } from '../../hooks/useColorConfig';
import { useState } from 'react';
import AppRoute from '../../routing/AppRoute';
import Recaptcha from '../../components/Recaptcha/Recaptcha';
import { PaymentGatewayType } from '../../types/fetch';
import { appConfig } from '../../config/appConfig';

const Cart = () => {
  const { partnerConfig: siteConfig, paymentGateway } = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const theme = useColorConfig();
  const [openOrderingUnavailableDialog, setOpenOrderingUnavailableDialog] =
    useState<boolean>(store?.isMobileActive === false ?? false);
  const navigate = useNavigate();
  const showRecaptcha =
    appConfig.recaptchaEnabled &&
    paymentGateway?.paymentGateway === PaymentGatewayType.FreedomPay;

  useStoreClosedCheck(store);

  if (!store || !shortId) {
    return null;
  }

  const handleClick = () => {
    setOpenOrderingUnavailableDialog(!openOrderingUnavailableDialog);
    navigate(
      generatePath(AppRoute.MENU, {
        storeId: String(store.shortId)
      })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <CartProviderV2 shortId={shortId}>
          <Helmet>
            <title>Order Status</title>
          </Helmet>
          <CartContent
            store={store}
            hasDiscount={siteConfig.hasDiscount}
            shortId={shortId}
          />
          <OrderingUnavailableDialog
            open={openOrderingUnavailableDialog}
            close={() => handleClick()}
          />
          <PickupTimeAlert />
          {showRecaptcha && <Recaptcha location={'CART'} />}
        </CartProviderV2>
      </AppLayout>
    </ThemeProvider>
  );
};

export default Cart;
