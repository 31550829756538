import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, styled } from '@mui/material';

interface PickNewTimeDialogProps {
  open: boolean;
  close: () => void;
}

const PickNewTimeDialog = (props: PickNewTimeDialogProps) => {
  const { t } = useTranslation();

  const StyledConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.colors.partner[500]
  }));

  return (
    <ConfirmationDialog
      open={props.open}
      title={t('error.noCapacity.firstTimeSelected.title')}
      content={t('error.noCapacity.firstTimeSelected.description')}
      data-testid="advanced-ordering-pick-times-dialog"
    >
      <StyledConfirmationButton
        onClick={props.close}
        data-testid="advanced-ordering-pick-times-dialog-ok"
      >
        {t('restaurant.main.ok')}
      </StyledConfirmationButton>
    </ConfirmationDialog>
  );
};

export default PickNewTimeDialog;
