import { appConfig } from '../../../config/appConfig';

const getBaseUrl = () => {
  if (appConfig.environment.toUpperCase() !== 'PROD') {
    return 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?';
  }
  return 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html?';
};

const inputOptions =
  'tokenizewheninactive=true&inactivityto=500&selectinputdelay=100&formatinput=true&cardnumbernumericonly=true&';

const tokenMessagingOptions = 'enhancedresponse=true&invalidinputevent=true&';

const inputPlaceholders =
  'placeholder=Enter%20card%20number&placeholdercvv=Enter%20CVV&placeholdermonth=MM&placeholderyear=YYYY&';

const cssDeclaration = 'css=';

const body = 'body%7Bmargin%3A%208px%200%200%200%3B%7D';

const boxSizingBorderBox = 'input%7Bbox-sizing%3Aborder-box%3B%7D';

const inputFontStyle =
  'input%7Bcolor%3A%20%231A1A1A%3B%20font-family%3Asans-serif%3B%20font-weight%3A400%3B%20font-size%3A16px%3B%20line-height%3A24px%3B%20letter-spacing%3A0.15px%3B%7D';

const inputPlaceholderStyle =
  'input%3A%3Aplaceholder%7Bcolor%3A%20%23667085%20!important%3B%20font-family%3Asans-serif%3B%20font-weight%3A10%3B%20font-size%3A16px%3B%20line-height%3A24px%3B%20letter-spacing%3A0.15px%3B%7D';

const inputErrorStyle = 'input.error%7Bborder%3A1px%20solid%20%23F04438%3B%7D';

const inputBorderStyle =
  'input%7Bborder%3A1px%20solid%20rgba(0%2C%200%2C%200%2C%200.12)%20!important%3B%7D';

const inputBorderRadius = '%23ccnumfield%7Bborder-radius%3A3.5px%3B%7D';

const cardNumberInputWidth = '%23ccnumfield%7Bwidth%3A100%25%3B%7D';

const inputMarginTop = 'input%7Bmargin-top%3A-8px%3B%7D';

const inputPadding =
  'input%7Bpadding-left%3A16px%3B%20padding-right%3A0px%3B%7D';

const inputHeight = 'input%7Bheight%3A48px%3B%7D%20%23';

export const generateIframeUrl = () => {
  return (
    getBaseUrl() +
    inputOptions +
    tokenMessagingOptions +
    inputPlaceholders +
    cssDeclaration +
    body +
    boxSizingBorderBox +
    inputFontStyle +
    inputPlaceholderStyle +
    inputErrorStyle +
    inputBorderStyle +
    inputBorderRadius +
    cardNumberInputWidth +
    inputMarginTop +
    inputPadding +
    inputHeight
  );
};
