import { useQuery } from '@tanstack/react-query';
import { PHONE_COUNTRY_QUERY_KEY } from '../constants';
import { fetchPhoneCountries } from '../services/fetchPhoneCountries';

export const usePhoneCountries = () => {
  return useQuery(
    [PHONE_COUNTRY_QUERY_KEY],
    async () => fetchPhoneCountries(),
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
};
