import { SessionKeys } from './Session';

const getSiteConfigFromSession = () => {
  const storedSiteSession = window.sessionStorage.getItem(
    SessionKeys.SITE_CONFIG_SESSION_KEY
  );

  try {
    return JSON.parse(storedSiteSession ?? '[]');
  } catch (e) {
    return []; // Ignore error
  }
};

export default getSiteConfigFromSession;
