import React, { createContext } from 'react';
import { ConfigInterface } from '../../types/fetch';

interface Props {
  siteConfig: ConfigInterface;
  children: React.ReactNode;
}

export const SiteConfigContext = createContext<ConfigInterface>(
  {} as ConfigInterface
);

export const SiteConfigProvider: React.FC<Props> = ({
  siteConfig,
  children
}) => {
  return (
    <SiteConfigContext.Provider value={siteConfig}>
      {children}
    </SiteConfigContext.Provider>
  );
};
