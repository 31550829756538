import { Cart } from '../../../contexts/Cart/CartContextV2';

export default function setPickupTime(
  state: Cart,
  pickupTime: Date | string | null
) {
  return {
    ...state,
    pickupTime
  };
}
