import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import AllergenIcon from '../AllergenIcon';
import { styled } from '@mui/material/styles';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { useTranslation } from 'react-i18next';
import Typography, {
  TypographyProps
} from '@mui/material/Typography/Typography';
import Grid from '@mui/material/Grid/Grid';
import { StoreInterface } from '../../types/stores';
import mapAllergenStringToSVG from '../../utils/API/mapAllergenStringToSVG';

const StyledDietaryKeyTitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(4),
    fontWeight: 400,
    padding: 0,
    textAlign: 'center'
  })
);

const StyledDietaryKeyInfo = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(3),
    fontWeight: 400,
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    lineHeight: theme.spacing(4)
  })
);

const StyledAllergenSubtitle = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(3),
    fontWeight: 600,
    padding: 0,
    textAlign: 'left',
    marginBottom: theme.spacing(2)
  })
);

const StyledAllergenContent = styled(DialogContent)<DialogContentProps>(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: 0
  })
);

const StyledAllergenItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  color: theme.colors.gray[450],
  fontSize: theme.spacing(3.5),
  padding: 0,
  paddingBottom: theme.spacing(2),
  '.MuiTypography-root.MuiListItemText-primary': {
    fontSize: theme.spacing(3.5),
    marginLeft: theme.spacing(2)
  }
}));

const StyledAllergenText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 400,
  paddingLeft: theme.spacing(2)
}));

interface RestaurantInfoDialogProps {
  store: StoreInterface;
}

const DietaryKey = ({ store }: RestaurantInfoDialogProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StyledDietaryKeyTitle
        id="allergen-dialog-title"
        data-testid="allergen-dialog-title"
      >
        {t('restaurant.allergens.dietaryKey')}:
      </StyledDietaryKeyTitle>
      <StyledDietaryKeyInfo>
        {t('restaurant.allergens.allergenInfo')}
      </StyledDietaryKeyInfo>
      <StyledAllergenContent sx={{ overflowY: 'visible' }}>
        {store.allergens && store.allergens.length ? (
          <>
            <StyledAllergenSubtitle
              id="allergen-subtitle"
              data-testid="allergen-subtitle"
            >
              {t('restaurant.allergens.allergens')}
            </StyledAllergenSubtitle>
            <Grid container>
              {store.allergens.map((allergen, id) => {
                allergen = allergen.toLowerCase();
                return (
                  <Grid key={id} item xs={6}>
                    <StyledAllergenItem
                      data-testid={`${allergen}-allergen-list-item`}
                    >
                      <AllergenIcon
                        AllergenSVG={mapAllergenStringToSVG(allergen)}
                        testIdExtension={`${allergen}`}
                      />
                      <StyledAllergenText data-testid="allergen-list-item-text">
                        {t(`restaurant.allergens.${allergen}`)}
                      </StyledAllergenText>
                    </StyledAllergenItem>
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}
        {store.dietaryOptions && store.dietaryOptions.length ? (
          <>
            <StyledAllergenSubtitle
              id="dietary-needs-subtitle"
              data-testid="dietary-needs-subtitle"
            >
              {t('restaurant.allergens.dietaryNeeds')}
            </StyledAllergenSubtitle>
            <Grid container>
              {store.dietaryOptions
                .filter(
                  // remove when we have paleo and keto icons
                  (string) =>
                    string.toLowerCase() !== 'paleo' ||
                    string.toLocaleLowerCase() !== 'keto'
                )
                .map((allergen, id) => {
                  allergen = allergen.toLowerCase();
                  return (
                    <Grid key={id} item xs={6}>
                      <StyledAllergenItem
                        data-testid={`${allergen}-allergen-list-item`}
                      >
                        <AllergenIcon
                          AllergenSVG={mapAllergenStringToSVG(allergen)}
                          testIdExtension={`${allergen}`}
                        />
                        <StyledAllergenText data-testid="allergen-list-item-text">
                          {t(`restaurant.allergens.${allergen}`)}
                        </StyledAllergenText>
                      </StyledAllergenItem>
                    </Grid>
                  );
                })}
            </Grid>
          </>
        ) : null}
      </StyledAllergenContent>
    </>
  );
};

export default DietaryKey;
