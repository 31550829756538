import { generatePath, useNavigate } from 'react-router-dom';
import AppRoute from '../routing/AppRoute';
import { useSiteConfig } from './useSiteConfig';
import { isStoreOpen } from '../utils/Store/isStoreOpen';
import { format } from 'date-fns';
import { StoreInterface } from '../types/stores';
import { useEffect } from 'react';

const useStoreClosedCheck = (store: StoreInterface | null | undefined) => {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const navigate = useNavigate();
  const today = format(new Date(), 'EEEE');

  useEffect(() => {
    if (!store) {
      return;
    }

    if (!isStoreOpen(store.days[today], siteConfig.timeZone)) {
      navigate(
        generatePath(AppRoute.CLOSED, {
          storeId: String(store?.shortId)
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);
};

export default useStoreClosedCheck;
