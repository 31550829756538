import React, { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from './AppHeader';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import AppRoute from '../../../routing/AppRoute';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../hooks/useStore';
import { useSiteConfig } from '../../../hooks/useSiteConfig';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import getMatchedParams from '../../../utils/Routing/getMatchedParams';

const BackButton = styled(Button)(({ theme }) => ({
  color: theme.colors.partner.font.primary,
  marginTop: theme.spacing(1)
}));

const StyledRestaurantTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 400,
  color: theme.colors.partner.font.primary,
  width: '12.75rem'
}));

const StyledsubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600,
  color: theme.colors.partner.font.primary,
  lineHeight: '1.1',
  width: '12.75rem'
}));

const StyledTitleContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  textAlign: 'center',
  zIndex: '-1',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
});

const StyledPlaceHolder = styled('div')(({ theme }) => ({
  float: 'left',
  marginLeft: theme.spacing(1.5)
}));

const SiteHeader: FC = () => {
  const { storeId: shortId } = getMatchedParams();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const shortCode = siteConfig?.shortCode.toLowerCase();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pathname = useLocation().pathname.toLowerCase();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');

  const showBackButton = () => {
    if (pathname.includes('closed')) {
      return true;
    }

    if (!shortCode || pathname.includes('/p/')) {
      return false;
    }

    return window?.history?.state?.idx >= 1;
  };

  const handleNavigate = () => {
    if (!pathname || !shortId) {
      navigate(-1);
    }

    if (
      pathname.toLowerCase() === `/r/${String(shortId).toLowerCase()}` ||
      pathname.toLowerCase().includes('closed')
    ) {
      navigate(
        generatePath(AppRoute.STORES, {
          siteId: String(shortCode)
        }),
        { replace: true }
      );
      return;
    }

    if (
      pathname.toLowerCase().includes('status') ||
      pathname.toLowerCase().includes('cart')
    ) {
      navigate(
        generatePath(AppRoute.MENU, {
          storeId: String(shortId).toLowerCase()
        }),
        { replace: true }
      );
      return;
    }

    navigate(-1);
  };

  return (
    <AppHeader>
      <StyledPlaceHolder>
        {showBackButton() && (
          <BackButton
            title={t('restaurant.main.back')}
            onClick={handleNavigate}
          >
            <ArrowBackIcon />
          </BackButton>
        )}
      </StyledPlaceHolder>
      <StyledTitleContainer>
        <StyledRestaurantTitle
          data-testid="partner-header-partner-name"
          noWrap={true}
        >
          {siteConfig.partnerName}
        </StyledRestaurantTitle>
        <StyledsubTitle data-testid="partner-header-subtitle" noWrap={true}>
          {store?.name ? store.name : t('restaurant.main.restaurants')}
        </StyledsubTitle>
      </StyledTitleContainer>
    </AppHeader>
  );
};

export default SiteHeader;
