import { styled } from '@mui/material/styles';
import { appConfig } from '../../config/appConfig';

const VersionContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(20),
  paddingBottom: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
}));

const Version = styled('h2')(() => ({
  textAlign: 'center',
  padding: '0',
  margin: '0'
}));

const AppVersion = () => {
  return (
    <VersionContainer>
      <Version>Commit: {appConfig.version}</Version>
      <Version>Branch: {appConfig.branch}</Version>
    </VersionContainer>
  );
};

export default AppVersion;
