import { FC, useEffect, useRef } from 'react';
import NotFoundError from './features/NotFoundError';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import logger, { LoggerEvent } from './services/logger';
import { useLocation } from 'react-router-dom';

export const Fallback: FC<FallbackProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const ref = useRef(location);

  useEffect(() => {
    if (ref.current !== location) {
      props.resetErrorBoundary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  logger.error(LoggerEvent.APP_ERROR, 'Unknown Error', props.error);

  switch (props.error.message) {
    case 'Missing Store':
      return <NotFoundError text={t('error.storeNotFound')} />;

    default:
      return <NotFoundError text={t('error.unknown')} />;
  }
};
