import { styled } from '@mui/material/styles';
import RectangleDiv from '../RectangleDiv/RectangleDiv';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
  IconButton,
  IconButtonProps,
  Typography,
  useTheme
} from '@mui/material';
export interface UpdateQuantityProps {
  quantity: number;
  handleDecrease: () => void;
  handleAdd: () => void;
  itemIndex?: number;
  disabled?: boolean;
}

const StyledQuantity = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4.25),
  fontWeight: 700,
  textAlign: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const StyledQuantityIconButton = styled(IconButton)<IconButtonProps>(
  ({ theme }) => ({
    padding: 0,
    color: theme.colors.base.black,
    '&.Mui-disabled': {
      color: theme.colors.gray[300]
    }
  })
);

const UpdateQuantity: React.FC<UpdateQuantityProps> = ({
  quantity,
  handleAdd,
  handleDecrease,
  itemIndex,
  disabled
}: UpdateQuantityProps) => {
  const theme = useTheme();

  return (
    <RectangleDiv>
      <StyledQuantityIconButton
        disabled={quantity === 1 || disabled}
        onClick={handleDecrease}
        data-testid="remove-button"
        aria-label={`Quantity of total ${quantity}. Decrease quantity, removes one of this item`}
      >
        <RemoveIcon />
      </StyledQuantityIconButton>

      <StyledQuantity
        variant="body1"
        aria-hidden={true}
        data-testid={`quantity-text-${itemIndex}`}
        sx={{
          color: disabled ? theme.colors.gray[300] : theme.colors.base.black
        }}
      >
        {quantity}
      </StyledQuantity>
      <StyledQuantityIconButton
        onClick={handleAdd}
        data-testid="add-button"
        disabled={disabled}
        aria-label={`Quantity of total ${quantity}. Increase quantity, adds one more of this item`}
      >
        <AddIcon />
      </StyledQuantityIconButton>
    </RectangleDiv>
  );
};

export default UpdateQuantity;
