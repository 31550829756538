import { differenceInMinutes } from 'date-fns';

const RANDOM_DATE = '01/01/2000';

export const isStoreAboutToClose = (userTime: string, closingTime: string) => {
  const userTimeObject = new Date(`${RANDOM_DATE} ${userTime}`);
  const closeTimeObject = new Date(`${RANDOM_DATE} ${closingTime}`);
  const timeDifference = differenceInMinutes(closeTimeObject, userTimeObject);

  // if timeDifference is a negative number,
  // it is already past the closing time for the store
  if (timeDifference > 0 && timeDifference <= 15) {
    return true;
  }
  return false;
};
