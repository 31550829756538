import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

interface InfoDrawerProps {
  show: boolean;
  onClose: (event: React.SyntheticEvent) => void;
  title: string;
  body?: string;
  disclaim?: string;
  subTitle?: string;
  'data-testid'?: string;
}

const InfoDrawer = styled(Drawer)(({}) => ({
  zIndex: 1500
}));

const InfoTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  borderBottom: `1px solid ${theme.colors.gray[300]}`
}));

const InfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4.25)
}));

const InfoSubtitle = styled(Typography)({
  fontWeight: 600
});

const InfoBodyContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const InfoBody = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  whiteSpace: 'pre-line'
}));

const InfoDisclaimContainer = styled('div')(({}) => ({
  display: 'flex'
}));

const InfoIcon = styled(ErrorOutlineOutlinedIcon)(({ theme }) => ({
  height: theme.spacing(4),
  marginRight: theme.spacing(2),
  marginTop: theme.spacing(1),
  width: theme.spacing(4)
}));

const InfoDisclaim = styled(Typography)(({}) => ({
  fontWeight: 600
}));

const InformationDrawer = (props: InfoDrawerProps) => {
  const { show, onClose, title, body, disclaim, subTitle } = props;

  return (
    <>
      <InfoDrawer
        anchor="bottom"
        open={show}
        onClose={onClose}
        data-testid={props['data-testid']}
      >
        <InfoTitleContainer>
          <InfoTitle data-testid={`${props['data-testid']}-title`}>
            {title}
          </InfoTitle>
        </InfoTitleContainer>
        <InfoBodyContainer data-testid={`${props['data-testid']}-sub-title`}>
          {subTitle && <InfoSubtitle>{subTitle}</InfoSubtitle>}
          {body && (
            <InfoBody data-testid={`${props['data-testid']}-body`}>
              {body}
            </InfoBody>
          )}
          {disclaim && (
            <InfoDisclaimContainer
              data-testid={`${props['data-testid']}-disclaimer`}
            >
              <span data-testid={`${props['data-testid']}-disclaimer-icon`}>
                <InfoIcon />
              </span>
              <InfoDisclaim
                data-testid={`${props['data-testid']}-disclaimer-text`}
              >
                {disclaim}
              </InfoDisclaim>
            </InfoDisclaimContainer>
          )}
        </InfoBodyContainer>
      </InfoDrawer>
    </>
  );
};

export default InformationDrawer;
