import { appConfig } from '../../config/appConfig';
import { SessionKeys } from './Session';

export default function validateStorageCache() {
  // Validate and clear older caches
  const storageVersion = localStorage.getItem(SessionKeys.VERSION);

  if (storageVersion === appConfig.version) {
    return;
  }

  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem(SessionKeys.VERSION, String(appConfig.version));
}
