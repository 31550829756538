import { FC } from 'react';
import UpdateQuantity from '../../../components/UpdateQuantity';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

interface MenuItemQuantityProps {
  quantity: number;
  disabled: boolean;
  onChange: (quantity: number) => void;
}

const StyledOrderQuantity = styled(Box)(({ theme }) => ({
  height: theme.spacing(12)
}));

const MenuItemQuantity: FC<MenuItemQuantityProps> = (props) => {
  const increaseQuantity = () => {
    props.onChange(props.quantity + 1);
  };

  const decreaseQuantity = () => {
    props.onChange(props.quantity - 1);
  };

  return (
    <StyledOrderQuantity>
      <UpdateQuantity
        disabled={props.disabled}
        quantity={props.quantity}
        handleAdd={increaseQuantity}
        handleDecrease={decreaseQuantity}
      />
    </StyledOrderQuantity>
  );
};

export default MenuItemQuantity;
