export interface MenuCategoryObserverFactoryInterface {
  screenTracker: (category: string) => void;
  category: string;
}

const MenuCategoryObserverFactory = (
  props: MenuCategoryObserverFactoryInterface
) => {
  // detects if element is at top of viewport to update active nav tab
  // docs for how/why this works
  // https://spacejelly.dev/posts/how-to-trigger-a-function-when-scrolling-to-an-element-in-react-intersection-observer/
  // https://www.telerik.com/blogs/observing-visibility-in-react
  const marginHeight = window.innerHeight - 101;
  const options = {
    root: null,
    rootMargin: `-100px 0px -${marginHeight}px 0px` // NO TOUCHY!!!
  };

  return new window.IntersectionObserver((entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      props.screenTracker(props.category);
    }
  }, options);
};

export default MenuCategoryObserverFactory;
