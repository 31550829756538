import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FormatPrice from '../../Menu/components/FormatPrice/FormatPrice';

const StyledAddToOrder = styled(Button)(({ theme }) => ({
  height: theme.spacing(12),
  width: '100%',
  backgroundColor: theme.colors.partner.button.primary.background,
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background
  },
  color: theme.colors.partner.button.primary.font,
  borderRadius: theme.spacing(2),
  '&.Mui-disabled': {
    backgroundColor: theme.colors.gray[200]
  }
}));

const StyledCancelButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.colors.partner.primary}`,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.base.white,
  color: theme.colors.partner.primary,
  height: theme.spacing(12),
  width: '100%'
}));

interface MenuItemAddToOrderProps {
  price: number;
  disabled: boolean;
  isEdit: boolean;
  isSuspended: boolean;
  showCancel: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const MenuItemAddToOrder: FC<MenuItemAddToOrderProps> = (props) => {
  const { t } = useTranslation();

  if (props.isSuspended) {
    return (
      <StyledAddToOrder
        disableRipple
        disabled={true}
        data-testid="item-suspended-button"
      >
        {t('restaurant.menu.suspended')}
      </StyledAddToOrder>
    );
  }
  if (!props.isEdit) {
    return (
      <StyledAddToOrder
        onClick={props.onSubmit}
        data-testid="add-to-order-button"
        disableRipple
        disabled={props.disabled}
      >
        <p>
          {t('restaurant.menu.addToOrderButtonLabel')}{' '}
          {FormatPrice(props.price)}
        </p>
      </StyledAddToOrder>
    );
  }

  if (props.showCancel) {
    return (
      <StyledCancelButton
        disableRipple
        onClick={props.onCancel}
        data-testid="cancel-button"
      >
        {t('restaurant.confirm.cancel')}
      </StyledCancelButton>
    );
  }

  return (
    <StyledAddToOrder
      disableRipple
      onClick={props.onSubmit}
      disabled={props.disabled}
      data-testid="update-order-button"
    >
      {t('restaurant.confirm.updateOrder')}
    </StyledAddToOrder>
  );
};

export default MenuItemAddToOrder;
