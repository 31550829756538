import { useTranslation } from 'react-i18next';
import { styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import QrCode from '@mui/icons-material/QrCode';
import Welcome from '../../components/Welcome';
import StepCard from '../../components/StepCard';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { setupTheme } from '../../utils/Theme/setupTheme';

const WelcomeContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingBottom: theme.spacing(8),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
}));

const qrCode = styled(QrCode)(({ theme }) => ({
  height: theme.spacing(8),
  width: 'auto',
  marginBottom: theme.spacing(4)
}));

const photoCamera = styled(PhotoCamera)(({ theme }) => ({
  height: theme.spacing(8),
  width: 'auto',
  marginBottom: theme.spacing(4)
}));

const WelcomeScreen = () => {
  const { t } = useTranslation();
  const steps = [
    {
      number: '1',
      text: t('restaurant.tutorial.stepOne'),
      icon: qrCode
    },
    {
      number: '2',
      text: t('restaurant.tutorial.stepTwo'),
      icon: photoCamera
    },
    {
      number: '3',
      text: t('restaurant.tutorial.stepThree'),
      icon: null
    }
  ];

  const siteConfig = useSiteConfig();
  const theme = setupTheme(siteConfig.colorConfig);

  if (!siteConfig) {
    return null;
  }
  return (
    <ThemeProvider theme={theme}>
      <WelcomeContainer>
        <Welcome />
        {steps.map((step, index) => (
          <StepCard
            number={step.number}
            text={step.text}
            icon={step.icon}
            key={index}
          />
        ))}
      </WelcomeContainer>
    </ThemeProvider>
  );
};

export default WelcomeScreen;
