import SiteButton from '../../../../components/SiteButton/SiteButton';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import AppRoute from '../../../../routing/AppRoute';

interface ViewCheckoutButtonProps {
  total?: number;
  shortId?: string;
}

const ViewCheckoutButton = ({ total, shortId }: ViewCheckoutButtonProps) => {
  const { t } = useTranslation();

  return (
    <>
      <SiteButton
        buttonText={t('restaurant.main.checkout')}
        open
        buttonLink={generatePath(AppRoute.CHECKOUT, {
          storeId: String(shortId)
        })}
        buttonPrice={total}
        testId="continue-to-checkout-button"
        descriptionTestId="checkout"
        amountTestId="checkout-amount"
      />
    </>
  );
};

export default ViewCheckoutButton;
