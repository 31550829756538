import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const WelcomeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(4)
}));

const WelcomeTitle = styled('h5')(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.spacing(6),
  fontWeight: 500,
  marginTop: 0,
  marginBottom: theme.spacing(1)
}));

const WelcomeText = styled('p')({
  maxWidth: '70%',
  textAlign: 'center',
  margin: '0'
});

const Welcome = () => {
  const { t } = useTranslation();
  return (
    <WelcomeContainer>
      <WelcomeTitle>{t('restaurant.tutorial.welcome')}</WelcomeTitle>
      <WelcomeText>{t('restaurant.tutorial.instructions')}</WelcomeText>
    </WelcomeContainer>
  );
};

export default Welcome;
