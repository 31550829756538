import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';

interface CardPointePreAuthRequest {
  cardNotPresent: boolean;
  siteId: string;
  account: string;
  amount: string;
  name: string;
  cvv?: string;
  expiry?: string;
  postal?: string;
  country?: string;
}

const cardpointePreauth = async (params: CardPointePreAuthRequest) => {
  const { siteId, account, amount, name, expiry, cvv, postal, country } =
    params;
  const preAuthPaymentUrl = `${backendConfig.apiBaseUrl}/eat/payment/cardp/partner/${siteId}/preAuth`;

  const requestBody = JSON.stringify({
    cardNotPresent: true,
    account: account,
    amount: amount,
    name: name,
    expiry: expiry,
    cvv: cvv,
    postal: postal ?? undefined,
    country: country ?? undefined
  });

  const axiosConfig = {
    method: 'POST',
    url: preAuthPaymentUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default cardpointePreauth;
