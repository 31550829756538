import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { CartItem } from '../../features/Cart/types';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '@mui/material/Button';
import UpdateQuantity from '../UpdateQuantity';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ModifierItems from '../../features/MenuItem/Components/ModifierItems/ModifierItems';
import { useAnalytics } from '../../hooks/useAnalytics';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AppRoute from '../../routing/AppRoute';
import { useCartV2 } from '../../hooks/useCartV2';
import FormatPrice from '../../features/Menu/components/FormatPrice/FormatPrice';

interface OrderItemProps {
  item: CartItem;
  itemIndex: number;
  removable: boolean;
  promoCode: string;
}

const OrderItemContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const ItemTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

const ItemPrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'flex-end'
}));

const StyledStrikeItemPrice = styled(Typography)(({ theme }) => ({
  color: theme.colors.gray[500],
  display: 'flex',
  justifyContent: 'flex-end',
  textDecoration: 'line-through',
  fontSize: theme.spacing(3),
  paddingTop: theme.spacing(1.5)
}));

const StyledDiscountCodeAmount = styled('div')(({ theme }) => ({
  color: theme.colors.gray[500],
  fontSize: theme.spacing(3),
  marginTop: theme.spacing(3)
}));

const StyledAlcoholDiscount = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.gray[100],
  borderRadius: theme.spacing(2),
  display: 'flex',
  fontSize: theme.spacing(3),
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  width: '100%'
}));

const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  height: theme.spacing(5),
  marginRight: theme.spacing(1),
  width: theme.spacing(5)
}));

const OrderActionButtonContainer = styled('div')(() => ({
  display: 'flex'
}));

const RemoveButton = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.spacing(3),
  fontWeight: 600,
  textTransform: 'uppercase',
  cursor: 'pointer'
}));

const EditButton = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: theme.spacing(3),
  marginRight: theme.spacing(8),
  fontWeight: 600,
  textTransform: 'uppercase',
  cursor: 'pointer'
}));

const ConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.partner[500]
}));

const ModifierGroup = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3)
}));

const StyledItemNotes = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3),
  marginTop: theme.spacing(1.5)
}));

const OrderItemDetail = ({
  item,
  itemIndex,
  removable,
  promoCode
}: OrderItemProps) => {
  const { t } = useTranslation();
  const { storeId: shortId } = useParams();
  const { removeItem, changeItemQuantity } =
    useCartV2();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const navigate = useNavigate();
  const logEvent = useAnalytics();
  const [quantity, setQuantity] = useState(item.quantity);

  const handleOpen = () => {
    setOpenConfirmationDialog(true);
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  useEffect(() => {
    const debouncer = setTimeout(() => {
      changeItemQuantity(item, itemIndex, quantity);
    }, 500);
    return () => {
      clearTimeout(debouncer);
    }
  }, [quantity]);

  const handleAdd = () => {
    setQuantity(quantity + 1);
  };
  const handleDecrease = () => {
    setQuantity(quantity - 1);
  };

  const handleEdit = () => {
    navigate(
      generatePath(AppRoute.MENU_ITEM_EDIT, {
        storeId: String(shortId),
        itemId: String(item.productId),
        editItemIndex: String(itemIndex)
      })
    );
  };

  const handleConfirmDelete = () => {
    setOpenConfirmationDialog(false);
    const analyticPayload = {
      item_qty: item.quantity,
      item_id: item.productId,
      category_name: item.category
    };
    removeItem(item, itemIndex);
    logEvent('remove_from_cart', analyticPayload);
  };

  return (
    <OrderItemContainer data-testid={`order-item-${item.productId}`}>
      <Grid container rowSpacing={4}>
        <Grid item xs={10}>
          <ItemTitle variant="subtitle1">
            <div data-testid="item-title">
              {item.quantity}
              <span aria-hidden={true}> </span>
              {item.title}
            </div>
          </ItemTitle>
          {item && item.modifiers && Object.keys(item.modifiers).length !== 0 && (
            <ModifierGroup>
              <ModifierItems modifiers={item.modifiers} />
            </ModifierGroup>
          )}
          {item.itemNotes && (
            <StyledItemNotes>{item.itemNotes}</StyledItemNotes>
          )}
          {item.itemTotalDiscountPrice < item.itemTotalPrice && (
            <StyledDiscountCodeAmount aria-label="discount code and amount discounted">
              {promoCode.toUpperCase() +
                ' ' +
                `(-${FormatPrice(
                  item.itemTotalPrice - item.itemTotalDiscountPrice
                )})`}
            </StyledDiscountCodeAmount>
          )}
        </Grid>
        <Grid item xs={2}>
          {item.itemTotalDiscountPrice === item.itemTotalPrice && (
            <ItemPrice data-testid="cost-of-items">
              {FormatPrice(item.itemTotalPrice)}
            </ItemPrice>
          )}
          {item.itemTotalDiscountPrice < item.itemTotalPrice && (
            <>
              <StyledStrikeItemPrice aria-label="before discount price">
                {FormatPrice(item.itemTotalPrice)}
              </StyledStrikeItemPrice>
              <ItemPrice data-testid="cost-of-items">
                {FormatPrice(item.itemTotalDiscountPrice)}
              </ItemPrice>
            </>
          )}
        </Grid>
        {item.attributes &&
          item.attributes.find(
            (attribute) => attribute.name.toLowerCase() === 'alcohol'
          ) &&
          promoCode && (
            <StyledAlcoholDiscount>
              <StyledInfoIcon></StyledInfoIcon>
              <span>{t('restaurant.cart.promoAlcohol')}</span>
            </StyledAlcoholDiscount>
          )}

        {removable && (
          <>
            <Grid item xs={6}>
              <UpdateQuantity
                quantity={quantity}
                handleAdd={handleAdd}
                handleDecrease={handleDecrease}
                itemIndex={itemIndex}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
              }}
            >
              <OrderActionButtonContainer data-testid="order-action-container">
                <EditButton
                  role="button"
                  onClick={handleEdit}
                  tabIndex={0}
                  data-testid={`edit-item-button-${itemIndex}`}
                >
                  {t('restaurant.confirm.edit')}
                </EditButton>

                <RemoveButton
                  aria-label={t('restaurant.confirm.remove')}
                  tabIndex={0}
                  data-testid={`remove-item-button-${itemIndex}`}
                  onClick={handleOpen}
                  role="button"
                >
                  {t('restaurant.confirm.remove')}
                </RemoveButton>
              </OrderActionButtonContainer>
            </Grid>
          </>
        )}
      </Grid>

      {removable && (
        <ConfirmationDialog
          open={openConfirmationDialog}
          handleClose={handleClose}
          title={t('restaurant.cart.removeItem')}
          content={
            t('restaurant.cart.removeConfirm') +
            ` ${item.quantity}` +
            ` ${item.title}` +
            t('restaurant.cart.removeConfirmTwo')
          }
          data-testid="confirmation-dialog"
        >
          <ConfirmationButton onClick={handleClose} data-testid="dialog-cancel">
            {t('restaurant.confirm.cancel')}
          </ConfirmationButton>
          <ConfirmationButton
            onClick={handleConfirmDelete}
            data-testid="dialog-remove"
          >
            {t('restaurant.confirm.remove')}
          </ConfirmationButton>
        </ConfirmationDialog>
      )}
    </OrderItemContainer>
  );
};

export default OrderItemDetail;
