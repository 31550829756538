import { SvgType } from '../../types/assets';
import { ReactComponent as GlutenFriendlySVG } from '../../assets/images/allergen-icons/gluten-friendly.svg';

const mapAttributeStringToSVG = (attributeString: string): SvgType | null => {
  switch (attributeString.toLowerCase()) {
    case 'gluten free': {
      return GlutenFriendlySVG;
    }
  }

  return null;
};

export default mapAttributeStringToSVG;
