import AppRoute from '../../routing/AppRoute';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface NoTimesAvailableDialogProps {
  open: boolean;
  close: () => void;
}

const NoTimesAvailableDialog = (props: NoTimesAvailableDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const StyledConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.colors.partner[500]
  }));

  return (
    <ConfirmationDialog
      open={props.open}
      title={t('error.noCapacity.closed.title')}
      content={t('error.noCapacity.closed.description')}
      data-testid="advanced-ordering-no-times-dialog"
    >
      <StyledConfirmationButton
        onClick={() => navigate(AppRoute.STORES)}
        data-testid="advanced-ordering-no-times-dialog-explore"
      >
        {t('restaurant.main.explore')}
      </StyledConfirmationButton>
      <StyledConfirmationButton
        onClick={() => props.close()}
        data-testid="advanced-ordering-no-times-dialog-ok"
      >
        {t('restaurant.main.ok')}
      </StyledConfirmationButton>
    </ConfirmationDialog>
  );
};

export default NoTimesAvailableDialog;
