import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { GatewayResponse } from '../../types/GatewayResponse';
import { GatewayError } from '../../GatewayError';
import SelectPaymentMethod from '../SelectPaymentMethod';
import { useEffect, useRef } from 'react';

const HEADER_HEIGHT = 56;

interface FreedomPayProps {
  siteId: string;
  storeId: string;
  menuId: number;
  transactionTotal: number;
  handleClose: () => void;
  orderTimeStamp: string;
  sessionTimeout: () => void;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const Header = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.base.white,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  width: '100%',
  maxWidth: '1024px',
  zIndex: 30000,
  boxShadow: 'none',
  height: '56px',
  transition: 'box-shadow 0.3s ease',
  '.closeIcon': {
    position: 'relative'
  },

  '&.scrolled': {
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)'
  }
}));

const FreedomPayContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  overflowY: 'auto',
  position: 'fixed',
  top: HEADER_HEIGHT,
  bottom: 0,
  width: '100%',
  maxWidth: '1024px',
  height: `calc(100% - ${HEADER_HEIGHT}px)`
}));

const FreedomPay = ({
  siteId,
  storeId,
  menuId,
  transactionTotal,
  handleClose,
  orderTimeStamp,
  sessionTimeout,
  onError,
  onSuccess
}: FreedomPayProps) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = containerRef.current;
    const onScroll = () => {
      if (container?.scrollTop && container.scrollTop < HEADER_HEIGHT) {
        if (
          headerRef.current &&
          headerRef.current.classList.contains('scrolled')
        ) {
          headerRef.current.classList.remove('scrolled');
        }
      }
      if (container?.scrollTop && container.scrollTop > HEADER_HEIGHT) {
        if (
          headerRef.current &&
          !headerRef.current.classList.contains('scrolled')
        ) {
          headerRef.current.classList.add('scrolled');
        }
      }
    };

    container?.addEventListener('scroll', onScroll);

    return () => container?.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <>
      <Header ref={headerRef} data-test-id="freedom-pay-header">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon className="closeIcon" />
        </IconButton>
      </Header>
      <FreedomPayContainer
        ref={containerRef}
        data-testid="freedom-pay-container"
      >
        <SelectPaymentMethod
          siteId={siteId}
          menuId={menuId}
          storeId={storeId}
          transactionTotal={transactionTotal}
          orderTimeStamp={orderTimeStamp}
          sessionTimeout={sessionTimeout}
          onError={onError}
          onSuccess={onSuccess}
        />
      </FreedomPayContainer>
    </>
  );
};

export default FreedomPay;
