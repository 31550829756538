import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ThemeProvider, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography/Typography';
import { useSiteConfig } from '../../../hooks/useSiteConfig';
import StoreHours from '../../../components/RestaurantHours';
import { useStore } from '../../../hooks/useStore';
import { useParams } from 'react-router-dom';
import { useColorConfig } from '../../../hooks/useColorConfig';
import AppLayout from '../../../layouts/AppLayout';

const ClosedContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(19.25),
  marginLeft: theme.spacing(8),
  marginRight: theme.spacing(8)
}));

const CloseContainerTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 700,
  marginTop: theme.spacing(3.83),
  textAlign: 'center'
}));

const CloseContainerText = styled('p')(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 400,
  marginTop: theme.spacing(1),
  textAlign: 'center'
}));

const Divider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5)
}));

const StoreClosed = () => {
  const { t } = useTranslation();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const theme = useColorConfig();

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <ClosedContainer data-testid="restaurant-closed-container">
          <AccessTimeIcon style={{ width: '40px', height: '40px' }} />
          <CloseContainerTitle>
            {t('restaurant.main.sorry')}
          </CloseContainerTitle>
          <CloseContainerText>
            {t('restaurant.main.closedMessage')}
          </CloseContainerText>
        </ClosedContainer>
        <Divider />
        {store && <StoreHours store={store} />}
      </AppLayout>
    </ThemeProvider>
  );
};

export default StoreClosed;
