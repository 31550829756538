import { useParams } from 'react-router-dom';
import { useSiteConfig } from './useSiteConfig';
import { useStore } from './useStore';
import { setupTheme } from '../utils/Theme/setupTheme';

export const useColorConfig = () => {
  const { storeId: shortId } = useParams();
  const siteConfig = useSiteConfig();
  const { data: store } = useStore(
    siteConfig.partnerConfig.partnerId,
    shortId ?? ''
  );

  if (store?.colorConfig) {
    return setupTheme(store.colorConfig);
  }
  return setupTheme(siteConfig.colorConfig);
};
