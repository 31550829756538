import { useState } from 'react';
import { MenuItem } from '../features/Menu/types';

export const useCategoryScroll = (availableCategories: {
  [key: string]: MenuItem[];
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [autoScroll, setAutoScroll] = useState<{
    tracker: string;
    scrollWait: Promise<void> | undefined;
  } | null>(null);
  const [manualScroll, setManualScroll] = useState<{ tracker: string } | null>(
    null
  );

  // determins if scrolling manually or tab navigation
  // to minimize updates to scroll position
  const clear = () => {
    setManualScroll(null);
    setAutoScroll(null);
  };

  const updateTab = (tracker: string) => {
    const index = Object.keys(availableCategories).indexOf(tracker);
    setTabIndex(index);
    clear();
  };

  if (autoScroll) {
    setScrolling(true);
    updateTab(autoScroll.tracker);
    autoScroll.scrollWait?.then(() => {
      setScrolling(false);
      clear();
    });
  }

  if (!autoScroll && manualScroll && !scrolling) {
    updateTab(manualScroll.tracker);
  }

  return {
    tabIndex,
    setAutoScroll,
    setManualScroll
  };
};
