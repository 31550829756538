import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, styled } from '@mui/material';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useCartV2 } from '../../hooks/useCartV2';
import AppRoute from '../../routing/AppRoute';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import DialogContentText, {
  DialogContentTextProps
} from '@mui/material/DialogContentText';
import { useEffect, useState } from 'react';
import { CartItem } from '../../features/Cart/types';
import { useMenu } from '../../hooks/useMenu';
import { StoreInterface } from '../../types/stores';

interface PickNewTimeDialogProps {
  open: boolean;
  close: () => void;
  itemsRemoved: CartItem[];
  store: StoreInterface;
}

const Confirmation = styled(Dialog)<DialogProps>(({ theme }) => ({
  '.MuiDialog-paper': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const ConfirmationTitle = styled(DialogTitle)<DialogTitleProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(4),
    fontWeight: 400
  })
);

const ConfirmationText = styled(DialogContentText)<DialogContentTextProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(3.5)
  })
);

const ItemNoLongerAvailableDialog = (props: PickNewTimeDialogProps) => {
  const { t } = useTranslation();
  const { items, removeItemsByProductIds } = useCartV2();
  const { pathname } = useLocation();
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const { refetch } = useMenu(props.store ? props.store.id : '');

  const StyledConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.colors.partner[500]
  }));

  const menuPath = generatePath(AppRoute.MENU, {
    storeId: String(props.store.shortId)
  });
  useEffect(() => {
    if (!redirect) {
      return;
    }

    navigate(menuPath);
  }, [menuPath, navigate, redirect]);

  if (!props.itemsRemoved || props.itemsRemoved.length <= 0) {
    return null;
  }

  const removeItems = async () => {
    const unavailableItems: number[] = props.itemsRemoved.map(
      (item) => item.productId
    );

    removeItemsByProductIds(unavailableItems);
    await refetch();
  };

  const browseMenuClick = async () => {
    await removeItems();

    if (pathname === menuPath) {
      props.close();
      return;
    }

    setRedirect(true);
  };

  const closeButtonClick = async () => {
    await removeItems();
    props.close();
  };

  const displayItems: string[] = [
    ...new Set(props.itemsRemoved.map((item) => item.title))
  ];

  const productIdsRemoved: number[] = props.itemsRemoved.map(
    (item) => item.productId
  );

  const cartAfterRemoval = items.filter(
    (item) => !productIdsRemoved.includes(item.productId)
  );

  return (
    <>
      <Confirmation open={props.open} data-testid="item-no-longer-avail-dialog">
        <ConfirmationTitle data-testid="confirmation-title">
          {t('error.itemNotAvailable.title')}
        </ConfirmationTitle>
        <DialogContent>
          <ConfirmationText data-testid="confirmation-text">
            {t('error.itemNotAvailable.description')}
          </ConfirmationText>
          <ul>
            {displayItems.map((item, index) => (
              <li key={`removed-${index}`}>{item}</li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          {pathname !== menuPath && (
            <StyledConfirmationButton
              onClick={browseMenuClick}
              data-testid="item-no-longer-avail-dialog-explore"
            >
              {t('restaurant.cart.browseMenu')}
            </StyledConfirmationButton>
          )}
          {(cartAfterRemoval.length > 0 || pathname === menuPath) && (
            <StyledConfirmationButton
              onClick={closeButtonClick}
              data-testid="item-no-longer-avail-dialog-ok"
            >
              {t('restaurant.main.ok')}
            </StyledConfirmationButton>
          )}
        </DialogActions>
      </Confirmation>
    </>
  );
};

export default ItemNoLongerAvailableDialog;
