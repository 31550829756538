import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText, {
  DialogContentTextProps
} from '@mui/material/DialogContentText';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface ConfirmationDialogProps {
  content?: string;
  title?: string;
  open: boolean;
  handleClose?: () => void;
  children?: ReactNode;
}

const Confirmation = styled(Dialog)<DialogProps>(({ theme }) => ({
  '.MuiDialog-paper': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const ConfirmationTitle = styled(DialogTitle)<DialogTitleProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(4),
    fontWeight: 400
  })
);

const ConfirmationText = styled(DialogContentText)<DialogContentTextProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(3.5)
  })
);

const ConfirmationDialog = ({
  content,
  handleClose,
  open,
  title,
  children
}: ConfirmationDialogProps) => {
  return (
    <>
      <Confirmation
        open={open}
        onClose={handleClose}
        data-testid="confirmation-dialog"
      >
        <ConfirmationTitle
          data-testid="confirmation-title"
          aria-label={`${title} ${content}`}
        >
          {title}
        </ConfirmationTitle>
        <DialogContent>
          <ConfirmationText data-testid="confirmation-text">
            {content}
          </ConfirmationText>
        </DialogContent>
        <DialogActions>{children}</DialogActions>
      </Confirmation>
    </>
  );
};

export default ConfirmationDialog;
