import { useSiteConfig } from '../../../../hooks/useSiteConfig';

const FormatPrice = (price: number) => {
  const { partnerConfig: siteConfig } = useSiteConfig();

  if (siteConfig.currency === 'GBP') {
    return price.toLocaleString('en-GB', {
      style: 'currency',
      currency: 'GBP'
    });
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

export default FormatPrice;
