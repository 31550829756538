import { styled, useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Player } from '@lottiefiles/react-lottie-player';
import FormatPrice from '../../../Menu/components/FormatPrice/FormatPrice';

const StyledCustomTipConfirmationContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const StyledCustomTipConfirmationText = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center'
}));

const CustomTipConfirmationAnimationWrapper = styled('div')(({ theme }) => ({
  height: theme.spacing(10),
  width: theme.spacing(10),
  position: 'relative'
}));

const CustomTipConfirmationHeader = styled('h2')(() => ({
  margin: 'auto'
}));

const StyledCustomTipConfirmationButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  width: theme.spacing(40),
  borderColor: theme.colors.partner.primary,
  borderRadius: theme.spacing(2),
  color: theme.colors.partner.primary,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  '&:hover': {
    borderColor: theme.colors.partner.primary,
    color: theme.colors.partner.primary
  }
}));

const StyledCustomAddTipConfirmationButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  width: theme.spacing(40),
  backgroundColor: theme.colors.partner.button.primary.background,
  color: theme.colors.partner.button.primary.font,
  borderRadius: theme.spacing(2),
  fontSize: theme.spacing(4),
  fontWeight: 400,
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background,
    color: theme.colors.partner.button.primary.font
  }
}));

interface CustomTipConfirmationProps {
  tip: number;
  handlePressAddCustomTip: () => void;
  handlePressChangeCustomTip: () => void;
}

const CustomTipConfirmation = (props: CustomTipConfirmationProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <StyledCustomTipConfirmationContainer>
      <CustomTipConfirmationAnimationWrapper>
        <Player
          autoplay
          loop={false}
          src="/animations/ThankyouHeart-Outline.json"
          style={{
            height: theme.spacing(50),
            width: theme.spacing(50),
            top: theme.spacing(-16),
            left: theme.spacing(-18.75),
            position: 'absolute',
            bottom: 0,
            margin: 'auto 0'
          }}
          keepLastFrame={true}
        />
      </CustomTipConfirmationAnimationWrapper>
      <CustomTipConfirmationHeader>
        {t('restaurant.checkout.tipConfirmation.thankYou')}
      </CustomTipConfirmationHeader>
      <StyledCustomTipConfirmationText>
        {t('restaurant.checkout.tipConfirmation.appreciate')}
      </StyledCustomTipConfirmationText>
      <div>
        <StyledCustomTipConfirmationButton
          variant="outlined"
          data-testid="customTipConfirmationChangeTipButton"
          disableRipple
          onClick={props.handlePressChangeCustomTip}
        >
          {t('restaurant.checkout.tipConfirmation.changeTip')}
        </StyledCustomTipConfirmationButton>
        <StyledCustomAddTipConfirmationButton
          variant="contained"
          data-testid="customTipConfirmationAddTipButton"
          disableRipple
          onClick={props.handlePressAddCustomTip}
        >
          {t('restaurant.checkout.tipConfirmation.addTip', {
            tipAmount: FormatPrice(props.tip)
          })}
        </StyledCustomAddTipConfirmationButton>
      </div>
    </StyledCustomTipConfirmationContainer>
  );
};

export default CustomTipConfirmation;
