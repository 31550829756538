import { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTipInput from '../CustomTipInput';
import InformationDrawer from '../../../../components/InformationDrawer/InformationDrawer';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { CartCalculation, TipPreview } from '../../../Cart/types';
import FormatPrice from '../../../Menu/components/FormatPrice/FormatPrice';

interface AddTipProps {
  tipAmount: number;
  tipPreview: TipPreview[];
  orderTotal: number;
  cartCalculations: { data: CartCalculation };
}

const tipValues = ['10%', '15%', '20%', 'CUSTOM'];

const StyledTipAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  marginTop: theme.spacing(2.25),
  '.MuiAccordionSummary-root': {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  '&.MuiPaper-root:before': {
    backgroundColor: 'transparent'
  },
  '.MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0)
  },
  '.MuiAccordionDetails-root': {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(0)
  },
  '.MuiPaper-elevation': {
    margin: theme.spacing(0)
  },
  '.MuiSvgIcon-root': {
    color: theme.colors.base.black
  }
}));

const StyledAddTipHeader = styled(AccordionSummary)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const StyledInfoIconButton = styled(IconButton)(({ theme }) => ({
  '.MuiIconButton-root': {
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginLeft: theme.spacing(2.5)
  }
}));

const StyledTipBtnContainer = styled(ToggleButtonGroup)<{
  value: string | null;
}>(({ theme, value }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  gap: value && value !== 'CUSTOM' ? theme.spacing(3) : theme.spacing(4),
  '.MuiToggleButtonGroup-grouped, .MuiToggleButtonGroup-grouped:not(:last-of-type), .MuiToggleButtonGroup-grouped:not(:first-of-type)':
    {
      height: theme.spacing(9),
      border: `1px solid ${theme.colors.partner.button.secondary.font}`,
      fontSize: theme.spacing(3.5),
      borderRadius: theme.spacing(2),
      marginLeft: 0
    }
}));

const StyledTipButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.colors.partner.button.secondary.font,
  backgroundColor: theme.colors.partner.button.secondary.background,
  flexGrow: 1,
  transition: 'all 450ms linear',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: theme.colors.partner.button.primary.background,
    color: theme.colors.partner.button.primary.font,
    border: `none`
  }
}));

const StyledTipAmount = styled(Typography)(({ theme }) => ({
  color: theme.colors.partner.button.primary.font,
  fontSize: theme.spacing(3),
  marginLeft: theme.spacing(0.5),
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

const StyledTipInputContainer = styled('div')({
  height: 0,
  overflow: 'hidden',
  transition: 'height 200ms linear',
  '&.expanded': {
    height: '4.5rem'
  }
});

const AddTip = ({ tipAmount, tipPreview, cartCalculations }: AddTipProps) => {
  const { t } = useTranslation();
  const [showTipInfo, setShowTipInfo] = useState(false);
  const { setTip, tip } = useCartV2();
  const [expanded, setExpanded] = useState(Number(tip.value) > 0);
  const logEvent = useAnalytics();
  const customTipContainer = useRef<HTMLInputElement | null>(null);

  const handleInfo = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setShowTipInfo(true);
  };

  const getTipDisplay = (tipValue: string, tipSelection: string | null) => {
    if (
      tipValue === 'CUSTOM' ||
      tipValue !== tipSelection ||
      tipAmount > 10000
    ) {
      // if tip amount is greater than $10k don't show
      return tipValue;
    }

    let display = 0;
    const percentSelected = Number(tipSelection.replace('%', ''));
    const percentValues = tipPreview.filter(
      (item) => item.percent === percentSelected
    );

    if (percentValues.length > 0) {
      display = percentValues[0].amount;
    }

    return (
      <>
        {tipValue}
        <StyledTipAmount>{`(${FormatPrice(display)})`}</StyledTipAmount>
      </>
    );
  };

  const [tipSelection, setTipSelection] = useState<string | null>(() => {
    if (tip.type === 'dollar') {
      return 'CUSTOM';
    }
    return `${tip.value}%`;
  });

  const [customInputDisabled, setCustomInputDisabled] = useState<boolean>(
    () => {
      return tip.type !== 'dollar';
    }
  );

  const handleTipSelection = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    event.preventDefault();
    setTipSelection(value);
    if (value !== 'CUSTOM') {
      setCustomInputDisabled(true);
      setTip({ type: value, value: null });
    }
  };

  const handleSelect = (val: string) => {
    if (val !== 'CUSTOM') {
      setCustomInputDisabled(true);
      return;
    }
    setTip({ type: '', value: 0 });
    setCustomInputDisabled(false);
  };

  // if custom tip applied, expand custom tip input by default
  useEffect(() => {
    if (tip.type === 'dollar') {
      customTipContainer.current?.classList.add('expanded');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <StyledTipAccordion
        disableGutters
        square
        expanded={expanded}
        onChange={(event, isExpanded) => {
          setExpanded(isExpanded);

          if (expanded) {
            return;
          }

          logEvent('tip_expanded');
        }}
      >
        <StyledAddTipHeader
          expandIcon={<ExpandMoreIcon />}
          aria-controls="addTip-panel-content"
          id="addTip-panel-header"
          data-testid="add-tip-header"
        >
          <Typography variant="h5">
            {t('restaurant.checkout.addTip')}
          </Typography>
          <StyledInfoIconButton
            aria-label="tip information"
            onClick={handleInfo}
          >
            <InfoOutlinedIcon />
          </StyledInfoIconButton>
        </StyledAddTipHeader>
        <AccordionDetails>
          <StyledTipBtnContainer
            value={tipSelection}
            exclusive
            onChange={handleTipSelection}
            aria-label="tip selection"
          >
            {tipValues.map((val, idx) => {
              return (
                <StyledTipButton
                  onClick={() => handleSelect(val)}
                  value={val}
                  aria-label={val}
                  key={idx}
                >
                  {getTipDisplay(val, tipSelection)}
                </StyledTipButton>
              );
            })}
          </StyledTipBtnContainer>
          {!customInputDisabled && (
            <StyledTipInputContainer
              ref={customTipContainer}
              className={'expanded'}
            >
              <CustomTipInput
                disabled={customInputDisabled}
                cartCalculations={cartCalculations}
              />
            </StyledTipInputContainer>
          )}
        </AccordionDetails>
      </StyledTipAccordion>
      <InformationDrawer
        show={showTipInfo}
        onClose={() => setShowTipInfo(false)}
        title={t('restaurant.checkout.addTip')}
        body={t('restaurant.checkout.tipInfo')}
      />
    </>
  );
};

export default AddTip;
