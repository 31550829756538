import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MenuItemDescriptionProps {
  description: string;
}

const Description = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
  weight: 400
}));

const ToggleButton = styled(Button)(({ theme }) => ({
  color: theme.colors.base.black,
  fontSize: theme.spacing(3.5),
  marginLeft: theme.spacing(1),
  padding: 0,
  textDecoration: 'underline',
  textTransform: 'lowercase'
}));

const MenuItemDescription = ({ description }: MenuItemDescriptionProps) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    if (description && description.length > 250) {
      setShowButton(true);
      setShowMore(true);
    }
  }, [description]);

  return (
    <>
      <Description data-testid="item-description">
        {showMore ? (
          <span data-testid="elipse">{description.substring(0, 250)}...</span>
        ) : (
          <span>{description}</span>
        )}
        {showButton && (
          <ToggleButton
            data-testid="show-more-button"
            onClick={() => setShowMore(!showMore)}
          >
            {' '}
            {showMore
              ? t('restaurant.menu.showMore')
              : t('restaurant.menu.showLess')}
          </ToggleButton>
        )}
      </Description>
    </>
  );
};

export default MenuItemDescription;
