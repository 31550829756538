import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
// eslint-disable-next-line import/no-extraneous-dependencies
import { visuallyHidden } from '@mui/utils';

function NavigationBox() {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);

  useEffect(() => {
    if (location === currentLocation) {
      return;
    }
    if (location.pathname.slice(1)) {
      setMessage(`Navigated to ${location.pathname.slice(1)} page.`);
    }
    setCurrentLocation(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box
      sx={visuallyHidden}
      role="status"
      aria-live="polite"
      aria-atomic="true"
    >
      {message}
    </Box>
  );
}

export default NavigationBox;
