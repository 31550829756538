import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { useCartV2 } from '../../../../hooks/useCartV2';
import FormatPrice from '../../../Menu/components/FormatPrice/FormatPrice';

interface RoundUpProps {
  previewRoundUp: number;
  show?: boolean;
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  marginBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  borderTop: `1px solid ${theme.colors.gray[300]}`,
  '.MuiAccordionSummary-root': {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  '.MuiAccordionSummary-content': {
    margin: theme.spacing(0)
  },
  '.MuiAccordionDetails-root': {
    marginBottom: theme.spacing(0),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  }
}));

const StyledAccordionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginTop: theme.spacing(4)
}));

const StyledDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  '&.expanded': {
    overflow: 'visible',
    display: 'block',
    WebkitLineClamp: 'none'
  }
}));

const StyledToggleButton = styled(Button)(({ theme }) => ({
  color: theme.colors.base.black,
  fontSize: theme.spacing(3.5),
  padding: 0,
  textDecoration: 'underline',
  textTransform: 'lowercase',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const StyledCheckboxContainer = styled('div')(({}) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}));

const StyledCheckbox = styled(Checkbox)<CheckboxProps>(({ theme }) => ({
  color: theme.colors.gray.checkbox,
  '.MuiSvgIcon-root': {
    width: '1.6rem',
    height: '1.6rem',
    position: 'relative',
    zIndex: 1
  },
  '&.Mui-checked': {
    color: theme.colors.partner.button.primary.background
  },
  '&.Mui-checked::before': {
    content: '""',
    backgroundColor: theme.colors.partner.button.primary.font,
    position: 'absolute',
    zIndex: 0,
    top: '13.5px',
    left: '12.5px',
    width: '1.1rem',
    height: '1rem'
  }
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontSize: theme.spacing(3.5)
  }
}));

const RoundUp: FC<RoundUpProps> = ({ previewRoundUp, show }: RoundUpProps) => {
  const descriptionRef = useRef<HTMLElement | null>(null);
  const { t } = useTranslation();
  const logEvent = useAnalytics();
  const [toggle, setToggle] = useState(false);
  const [isShow, setShow] = useState(show ?? false);
  const { hasRoundUp, setHasRoundUp } = useCartV2();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const roundUpTitle = siteConfig.roundUpTitle;
  const roundUpDescription = siteConfig.roundUpDescription;

  const handleAccordionChange = (
    e: React.SyntheticEvent,
    expanded: boolean
  ) => {
    if (!expanded) {
      return;
    }
    logEvent('round_up_expanded');
  };

  const handleToggle = () => {
    descriptionRef.current?.classList.toggle('expanded');
    setToggle(!toggle);
  };

  const handleChange = () => {
    setHasRoundUp(!hasRoundUp);
  };

  useEffect(() => {
    if (
      descriptionRef.current &&
      descriptionRef.current.offsetHeight > 0 &&
      descriptionRef.current.offsetHeight < descriptionRef.current.scrollHeight
    ) {
      setShow(true);
    }
  }, [descriptionRef]);

  return (
    <>
      <StyledAccordion
        defaultExpanded={true}
        disableGutters
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#1a1a1a' }} />}
          data-testid="accordion-summary"
        >
          <Typography variant="h5" data-testid="round-up-header">
            {t('restaurant.checkout.roundUp')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ marginBottom: 0 }}>
          <StyledAccordionTitle data-testid="round-up-title">
            {roundUpTitle}
          </StyledAccordionTitle>
          <StyledDescription
            ref={descriptionRef}
            data-testid="roundup-description"
          >
            {roundUpDescription}
          </StyledDescription>
          {isShow && (
            <StyledToggleButton
              disableRipple
              onClick={handleToggle}
              data-testid="toggle-roundup-button"
            >
              {toggle
                ? t('restaurant.menu.showLess')
                : t('restaurant.menu.showMore')}
            </StyledToggleButton>
          )}
          <StyledCheckboxContainer data-testid="roundup-checkbox-container">
            <StyledFormControlLabel
              control={
                <StyledCheckbox
                  onChange={handleChange}
                  checked={hasRoundUp}
                  data-testid="roundup-checkbox"
                />
              }
              label={t('restaurant.checkout.roundUpLabel')}
            />
            <span>{FormatPrice(previewRoundUp)}</span>
          </StyledCheckboxContainer>
        </AccordionDetails>
      </StyledAccordion>
    </>
  );
};

export default RoundUp;
