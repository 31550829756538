import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControl from '@mui/material/FormControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/CheckRounded';
import MUIErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import ItemModifierGroup from '../ItemModifierGroup';
import ModifierItems from '../ModifierItems/ModifierItems';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { MenuItem, MenuItemModGroup, ModItem } from '../../../Menu/types';
import { motion } from 'framer-motion';

export interface DropdownMenuProps {
  title: string;
  required?: boolean;
  selections: MenuItemModGroup;
  addSelection: (itemModifier: ModItem) => void;
  removeSelection: (itemModifier: ModItem) => void;
  selectionError: boolean;
  selectedOptions: { [index: string]: ModItem } | undefined;
  menuItem: MenuItem;
  initialState: boolean;
}

const Dropdown = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  borderTop: `1px solid ${theme.colors.gray[300]}`,
  '.MuiAccordionSummary-root': {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  '&.MuiAccordion-root:before': {
    display: 'none'
  },
  '.MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0)
  },
  '.MuiAccordionDetails-root': {
    marginBottom: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(0)
  },
  '.MuiPaper-elevation': {
    margin: theme.spacing(0)
  }
}));

const DropdownTitleContainer = styled('div')({
  width: '100%'
});

const DropdownTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600
}));

const RequiredIcon = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(15),
  backgroundColor: theme.colors.gray[500],
  borderRadius: theme.spacing(3.25),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3.2)
}));

const RequiredText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.spacing(3),
  fontWeight: 400,
  color: theme.colors.base.white,
  display: 'inline',
  position: 'relative',
  top: '-1px'
}));

const ErrorIcon = styled(MUIErrorIcon)(({ theme }) => ({
  color: theme.colors.error[500],
  marginRight: theme.spacing(4)
}));

const SuccessIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.colors.base.white,
  fontSize: theme.spacing(3),
  position: 'relative',
  top: '-1px'
}));

const RenderStatusIcon = ({ statusIcon }: { statusIcon: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  switch (statusIcon) {
    case 'error': {
      return (
        <RequiredIcon>
          <ErrorIcon data-testid="error-icon" />
        </RequiredIcon>
      );
    }
    case 'required':
    case 'success': {
      return (
        <RequiredIcon
          animate={
            statusIcon === 'success' && {
              backgroundColor: theme.colors.success[500]
            }
          }
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 15,
            duration: 0.2
          }}
        >
          <motion.div
            layout
            initial={{
              opacity: 0,
              display: 'none',
              position: 'relative'
            }}
            animate={
              statusIcon === 'success' && {
                opacity: 1,
                display: 'inline',
                marginRight: theme.spacing(2),
                position: 'relative'
              }
            }
            transition={{
              type: 'spring',
              stiffness: 400,
              damping: 15,
              duration: 0.2
            }}
          >
            <SuccessIcon viewBox="0 0 20 20" data-testid="success-icon" />
          </motion.div>
          <RequiredText>{t('restaurant.menu.required')}</RequiredText>
        </RequiredIcon>
      );
    }
    default:
      return null;
  }
};

const DropdownMenu = ({
  title,
  required = false,
  selections,
  addSelection,
  removeSelection,
  selectionError,
  selectedOptions,
  menuItem,
  initialState
}: DropdownMenuProps) => {
  const [expanded, setExpanded] = useState<boolean>(initialState);
  const logEvent = useAnalytics();

  const getGroupItems = (): { [index: string]: ModItem } => {
    const returnValue = {};

    for (const key in selectedOptions) {
      if (!selectedOptions.hasOwnProperty(key)) {
        continue;
      }

      if (selectedOptions[key].modifierName !== title) {
        continue;
      }

      returnValue[key] = selectedOptions[key];
    }

    return returnValue;
  };

  const calculateStatus = () => {
    if (selectionError) {
      return 'error';
    }

    if (required && Object.keys(getGroupItems()).length > 0) {
      return 'success';
    }

    if (required) {
      return 'required';
    }
    return '';
  };

  const status = calculateStatus();

  const handleExpanded = () => {
    setExpanded(!expanded);
    if (title === 'Included Ingredients') {
      logEvent('included_ingredients_selected', {
        item_id: Number(menuItem.productId),
        category_name: menuItem.category
      });
    }
  };

  return (
    <Dropdown
      expanded={expanded}
      aria-expanded={!expanded}
      onChange={handleExpanded}
      square
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: '#1A1A1A' }} />}
        id={`${title}-header`}
        data-testid={`${title}-accordion`}
      >
        <DropdownTitleContainer>
          <div>
            <DropdownTitle>{title}</DropdownTitle>
            <RenderStatusIcon statusIcon={status} />
          </div>
          {!expanded && <ModifierItems modifiers={getGroupItems()} />}
        </DropdownTitleContainer>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <ItemModifierGroup
            modName={title}
            group={selections}
            selectedGroupItems={getGroupItems()}
            addSelection={addSelection}
            removeSelection={removeSelection}
            key={`item_modifier_group_${title}`}
            data-testid={`${title}-modifier-group`}
          />
        </FormControl>
      </AccordionDetails>
    </Dropdown>
  );
};

export default DropdownMenu;
