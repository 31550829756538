import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';

export interface CardPointeRequest {
  encryptionHandler: 'EC_APPLE_PAY' | 'EC_GOOGLE_PAY';
  deviceData?: string;
  siteId: string;
}

const cardpointeTokenize = async (params: CardPointeRequest) => {
  const { encryptionHandler, deviceData, siteId } = params;
  const tokenizeEndpoint = `${backendConfig.apiBaseUrl}/eat/payment/cardp/site/${siteId}/tokenize`;

  const requestBody = JSON.stringify({
    encryptionHandler: encryptionHandler,
    deviceData: deviceData
  });

  const axiosConfig = {
    method: 'POST',
    url: tokenizeEndpoint,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default cardpointeTokenize;
