import axios from 'axios';
import { backendConfig } from '../../../config/appConfig';
import { FREEDOM_PAY } from '../../../config/backendServicesEndpoints';
import { BillingFormValues } from '../../../features/Gateways/FreedomPay/CollectBilling/CollectBilling';

const validateBillingInfo = async (data: BillingFormValues) => {
  return axios.post(
    `${FREEDOM_PAY}/initialize/validate`,
    {
      ...data,
      Country: data.Country?.alpha2,
      BillingCountryCode: data.Country?.num3
    },
    { headers: { 'x-api-key': backendConfig.apiKey } }
  );
};
export default validateBillingInfo;
