import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface CardpointeErrorToastProps {
  dismissToast: () => void;
}

const ToastContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: theme.spacing(12),
  color: theme.colors.base.white,
  backgroundColor: theme.colors.base.black,
  fontSize: theme.spacing(3),
  fontWeight: 400,
  letterSpacing: '0.4px',
  width: '100%',
  font: 'Roboto'
}));

const ErrorMessage = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));
const OkButton = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.colors.base.white,
  justifySelf: 'flex-end',
  padding: 0
}));

export const CardpointeErrorToast = ({
  dismissToast
}: CardpointeErrorToastProps) => {
  const { t } = useTranslation();

  return (
    <ToastContainer>
      <ErrorMessage>{t('error.cardpointeErrorToast')}</ErrorMessage>
      <OkButton onClick={dismissToast}>{t('restaurant.main.ok')}</OkButton>
    </ToastContainer>
  );
};

export default CardpointeErrorToast;
