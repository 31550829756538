import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const LoadingMenuContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(20),
  paddingTop: theme.spacing(4.5),
  paddingBottom: theme.spacing(4.5),
  paddingLeft: theme.spacing(6.5),
  paddingRight: theme.spacing(6.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.partner.primary,
  marginRight: theme.spacing(4.5)
}));

const LoadingText = styled('p')(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(7.5),
  fontSize: theme.spacing(5),
  fontWeight: 500,
  color: theme.colors.base.black
}));

const LoadingMenu = () => {
  const { t } = useTranslation();
  return (
    <LoadingMenuContainer>
      <LoadingText data-testid="menu-loading-text">
        {t('restaurant.menu.loading') + '...'}
      </LoadingText>
      <LoadingSpinner size={30} data-testid="menu-loading-spinner" />
    </LoadingMenuContainer>
  );
};

export default LoadingMenu;
