import { SvgType } from '../../types/assets';
import { ReactComponent as ContainsDairySVG } from '../../assets/images/allergen-icons/contains-dairy.svg';
import { ReactComponent as ContainsNutsSVG } from '../../assets/images/allergen-icons/contains-nuts.svg';
import { ReactComponent as ContainsPeanutSVG } from '../../assets/images/allergen-icons/contains-peanut.svg';
import { ReactComponent as ContainsFishSVG } from '../../assets/images/allergen-icons/contains-fish.svg';
import { ReactComponent as CerealsContainingGlutenSVG } from '../../assets/images/allergen-icons/cereals-containing-gluten.svg';
import { ReactComponent as CrustaceansSVG } from '../../assets/images/allergen-icons/contains-crustacean.svg';
import { ReactComponent as EggsSVG } from '../../assets/images/allergen-icons/contains-egg.svg';
import { ReactComponent as MolluscsSVG } from '../../assets/images/allergen-icons/contains-molluscs.svg';
import { ReactComponent as MustardSVG } from '../../assets/images/allergen-icons/contains-mustard.svg';
import { ReactComponent as HalalSVG } from '../../assets/images/allergen-icons/halal.svg';
import { ReactComponent as ContainsLupinSVG } from '../../assets/images/allergen-icons/contains-lupin.svg';
import { ReactComponent as ContainsSesameSeedsSVG } from '../../assets/images/allergen-icons/contains-sesame-seeds.svg';
import { ReactComponent as ContainsSoySVG } from '../../assets/images/allergen-icons/contains-soy.svg';
import { ReactComponent as ContainsCelerySVG } from '../../assets/images/allergen-icons/contains-celery.svg';
import { ReactComponent as ContainsSulphitesSVG } from '../../assets/images/allergen-icons/contains-sulphites.svg';
import { ReactComponent as LupinFreeSVG } from '../../assets/images/allergen-icons/lupin-free.svg';
import { ReactComponent as DairyFreeSVG } from '../../assets/images/allergen-icons/dairy-free.svg';
import { ReactComponent as GlutenFriendlySVG } from '../../assets/images/allergen-icons/gluten-friendly.svg';
import { ReactComponent as SoyFreeSVG } from '../../assets/images/allergen-icons/soy-free.svg';
import { ReactComponent as VeganSVG } from '../../assets/images/allergen-icons/vegan.svg';
import { ReactComponent as VegetarianSVG } from '../../assets/images/allergen-icons/vegetarian.svg';
import { ReactComponent as KetoSVG } from '../../assets/images/allergen-icons/keto.svg';
import { ReactComponent as PaleoSVG } from '../../assets/images/allergen-icons/paleo.svg';
import { ReactComponent as PlantBasedSVG } from '../../assets/images/allergen-icons/plant-based.svg';
import { ReactComponent as KosherSVG } from '../../assets/images/allergen-icons/kosher.svg';

const mapAllergenStringToSVG = (allergenString: string): SvgType => {
  switch (allergenString.toLowerCase()) {
    case 'contains dairy': {
      return ContainsDairySVG;
    }
    case 'contains nuts': {
      return ContainsNutsSVG;
    }
    case 'contains peanut': {
      return ContainsPeanutSVG;
    }
    case 'contains fish': {
      return ContainsFishSVG;
    }
    case 'cereals containing gluten': {
      return CerealsContainingGlutenSVG;
    }
    case 'crustaceans': {
      return CrustaceansSVG;
    }
    case 'eggs': {
      return EggsSVG;
    }
    case 'molluscs': {
      return MolluscsSVG;
    }
    case 'mustard': {
      return MustardSVG;
    }
    case 'nuts': {
      return ContainsNutsSVG;
    }
    case 'peanuts': {
      return ContainsPeanutSVG;
    }
    case 'milk': {
      return ContainsDairySVG;
    }
    case 'fish': {
      return ContainsFishSVG;
    }
    case 'halal': {
      return HalalSVG;
    }
    case 'lupin': {
      return ContainsLupinSVG;
    }
    case 'sesame seeds': {
      return ContainsSesameSeedsSVG;
    }
    case 'soy': {
      return ContainsSoySVG;
    }
    case 'soya': {
      return ContainsSoySVG;
    }
    case 'sulphites': {
      return ContainsSulphitesSVG;
    }
    case 'suphure dioxide (sulphites)': {
      return ContainsSulphitesSVG;
    }
    case 'celery': {
      return ContainsCelerySVG;
    }
    case 'lupin free': {
      return LupinFreeSVG;
    }
    case 'dairy free': {
      return DairyFreeSVG;
    }
    case 'gluten free': {
      return GlutenFriendlySVG;
    }
    case 'soy free': {
      return SoyFreeSVG;
    }
    case 'vegan': {
      return VeganSVG;
    }
    case 'vegetarian': {
      return VegetarianSVG;
    }
    case 'plant based': {
      return PlantBasedSVG;
    }
    case 'keto': {
      return KetoSVG;
    }
    case 'paleo': {
      return PaleoSVG;
    }
    case 'kosher': {
      return KosherSVG;
    }
    default: {
      return EggsSVG;
    }
  }
};

export default mapAllergenStringToSVG;
