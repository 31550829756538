import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { StoreInterface } from '../../../../types/stores';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import InfoDialog from '../../../../components/RestaurantInfoDialog';

import { isStoreOpen } from '../../../../utils/Store/isStoreOpen';
import { format } from 'date-fns';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import StatusLabel from '../../../../components/StatusLabel/StatusLabel';

const StyledCard = styled(ListItem, {
  shouldForwardProp: (prop: string) => prop !== 'imageUrl'
})<{ imageUrl: string }>(({ imageUrl, theme }) => ({
  backgroundImage: `linear-gradient(360deg, #000000, rgba(0, 0, 0, 0)), url(${imageUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  width: 'auto',
  height: '25vh',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start'
}));

const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.base.white
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.colors.base.white,
  letterSpacing: 0.25,
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2
}));
const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  fontWeight: 400,
  paddingLeft: theme.spacing(1),
  color: theme.colors.base.white
}));

const StyledInfoContainer = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  background: 'transparent',
  outline: 'none',
  border: 'none'
}));

const InfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fill: theme.colors.base.white,
  height: theme.spacing(4.16),
  width: theme.spacing(4.16)
}));

const StyledClosed = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(10)
}));

interface StoreDetailsProps {
  store: StoreInterface;
  isOpen: boolean;
}

function StoreDetails({ store, isOpen }: StoreDetailsProps) {
  const { t } = useTranslation();
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const handleInfoClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenInfoDialog(false);
  };

  const handleInfoOpen = (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenInfoDialog(true);
  };
  return (
    <Link
      to={isOpen ? `/r/${store.shortId}` : '#'}
      state={{
        title: store.name
      }}
      tabIndex={0}
      style={{ textDecoration: 'none' }}
      onClick={isOpen ? () => null : handleInfoOpen}
      aria-labelledby={`info-${store.shortId}`}
      data-testid={`info-${store.shortId}`}
    >
      <StyledCard
        data-testid={`restaurant-${store.shortId}`}
        imageUrl={store.primary}
      >
        <span id={`info-${store.shortId}`}>
          {!isOpen && (
            <StyledClosed>
              <StatusLabel labelText={t('restaurant.main.closed')} />
            </StyledClosed>
          )}
          {isOpen && !store.isMobileActive && (
            <StatusLabel
              labelText={`${t('restaurant.main.orderingUnavailableTitle')}`}
            />
          )}
          <StyledTitle variant="h5" data-testid="restaurant-name">
            {store.name}
          </StyledTitle>
          <Subtitle variant="body2" data-testid="restaurant-description">
            {t('restaurant.main.pickup')}
            <span aria-hidden="true"> • </span>
            {store.description}
          </Subtitle>
        </span>
        <StyledInfoContainer
          aria-label="restaurant info"
          onClick={handleInfoOpen}
        >
          <InfoIcon />

          <InfoText>{t('restaurant.main.info')}</InfoText>
        </StyledInfoContainer>

        <InfoDialog
          open={openInfoDialog}
          handleClose={handleInfoClose}
          store={store}
        />
      </StyledCard>
    </Link>
  );
}

interface RestaurantCardProps {
  store: StoreInterface;
}

export default function StoreCard({ store }: RestaurantCardProps) {
  const { partnerConfig: siteConfig } = useSiteConfig();

  const today = format(new Date(), 'EEEE');
  const isOpen = isStoreOpen(store.days[today], siteConfig.timeZone);

  return <StoreDetails store={store} isOpen={isOpen} />;
}
