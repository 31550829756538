import { ThemeProvider, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { setupTheme } from '../../utils/Theme/setupTheme';
import AppLayout from '../../layouts/AppLayout';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { emptyConfig } from '../../models/emptyConfig';

interface NotFoundErrorProps {
  text: string;
}

const StyledContainer = styled('div')(({ theme }) => ({
  height: '100%',
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
}));

const StyledWarningIcon = styled(WarningAmberIcon)(({ theme }) => ({
  height: theme.spacing(9),
  width: theme.spacing(10),
  marginBottom: theme.spacing(3)
}));

const StyledTextDisplay = styled(Typography)(({ theme }) => ({
  color: theme.colors.base.black,
  fontSize: theme.spacing(5),
  fontWeight: 500,
  whiteSpace: 'pre-line'
}));

const NotFoundError = ({ text }: NotFoundErrorProps) => {
  let siteConfig = useSiteConfig();

  if (Object.keys(siteConfig).length === 0) {
    siteConfig = emptyConfig;
  }

  const theme = setupTheme(siteConfig.colorConfig);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppLayout>
        <StyledContainer>
          <StyledWarningIcon />
          <StyledTextDisplay>{text}</StyledTextDisplay>
        </StyledContainer>
      </AppLayout>
    </ThemeProvider>
  );
};

export default NotFoundError;
