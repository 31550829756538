import { backendConfig } from '../config/appConfig';
import { Country } from '../types/countries';
import { http } from './http';

export default async function fetchAllowedCountries(): Promise<Country[]> {
  return http
    .get(`${backendConfig.apiBaseUrl}/eat/payment/countries`, {
      headers: { 'x-api-key': backendConfig.apiKey }
    })
    .then((response) => response.data);
}
