import { useEffect, useRef } from 'react';

interface CreditCardIframeProps {
  iframeHtml: string;
  sessionTimeout: () => void;
  onReceivePaymentKey: (key: string) => void;
  onReceive3dsData: (data: unknown) => void;
}

const CreditCardIframe: React.FC<CreditCardIframeProps> = ({
  iframeHtml,
  sessionTimeout,

  onReceivePaymentKey,
  onReceive3dsData
}) => {
  const iframeContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      sessionTimeout();
    }, 1800000); // 30 minutes

    return () => {
      clearTimeout(timeoutId);
    };
  }, [iframeHtml, sessionTimeout]);

  const createIframe = () => {
    return {
      __html: iframeHtml
    };
  };

  useEffect(() => {
    const messageEventListener = async (e: MessageEvent) => {
      const message = e.data;
      const data = message.data;
      switch (message.type) {
        case 2:
          if (iframeContainerRef.current) {
            iframeContainerRef.current.firstElementChild?.setAttribute(
              'height',
              data.height
            );
          }
          break;

        case 3: // event listener for getting payment key from iframe response
          // setPaymentKey(data.paymentKeys[0]);

          onReceivePaymentKey(data.paymentKeys[0]);
          // statusProcessing();
          break;
        // 14 or 16 receive 3DS parameters for security validation
        case 14:
        case 16:
          onReceive3dsData(data);

          break;
        default:
          break;
      }
    };

    window.addEventListener('message', messageEventListener);

    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  }, [onReceivePaymentKey, onReceive3dsData, iframeHtml]);

  return (
    <div
      data-testid="iframe-renderer"
      ref={iframeContainerRef}
      style={{ height: '450px' }}
      dangerouslySetInnerHTML={createIframe()}
    />
  );
};

export default CreditCardIframe;
