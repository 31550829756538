import { appConfig } from '../../../config/appConfig';

const loadScript = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(
      `script[src="${appConfig.freedomPayScript}"]`
    );

    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = appConfig.freedomPayScript;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load script'));

    document.body.appendChild(script);
  });
};

export default loadScript;
