import { zonedTimeToUtc } from 'date-fns-tz';

export interface Times {
  date: Date;
  isAsap: boolean;
}

export function processPickupTimeResponse(
  data: string[] | null,
  timeZone: string
): Times[] {
  if (!data) {
    return [];
  }

  const today = new Date();
  const returnValues: Times[] = data
    .filter((value) => /\d\d:\d\d:\d\d/.test(value))
    .map((value) => {
      const splice = value.split(':');
      const toDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        Number(splice[0]),
        Number(splice[1]),
        Number(splice[2])
      );
      return {
        date: zonedTimeToUtc(toDate, timeZone),
        isAsap: false
      };
    });

  if (data.includes('ASAP')) {
    returnValues.unshift({
      date: new Date(),
      isAsap: true
    });
  }

  return returnValues;
}
