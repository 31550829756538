import { FC, useState } from 'react';
import { MenuItem } from '../../Menu/types';
import CaliforniaProp65 from './CaliforniaProp65/CaliforniaProp65';
import AlcoholSnackBar from './AlcoholSnackBar/AlcoholSnackBar';
import { useSiteConfig } from '../../../hooks/useSiteConfig';

interface MenuItemAlcoholProps {
  item: MenuItem;
}

const MenuItemAlcohol: FC<MenuItemAlcoholProps> = (props) => {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const isCalifornia = siteConfig.warningFlags.includes('Prop 65');
  const [isAlcohol, setIsAlcohol] = useState(
    props.item.attributes.includes('Alcohol')
  );

  return (
    <>
      {isCalifornia && isAlcohol && <CaliforniaProp65></CaliforniaProp65>}
      <AlcoholSnackBar
        open={isAlcohol}
        handleClose={() => setIsAlcohol(false)}
      ></AlcoholSnackBar>
    </>
  );
};

export default MenuItemAlcohol;
