import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export interface CircleDivProps {
  children?: React.ReactNode;
  style: React.CSSProperties;
}

const Circle = styled(Box)(({ theme }) => ({
  height: theme.spacing(14),
  width: theme.spacing(14),
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
}));

const CircleDiv: React.FC<CircleDivProps> = (props) => {
  return (
    <Circle style={props.style} data-testid="circle-div">
      {props.children}
    </Circle>
  );
};

export default CircleDiv;
