import { Cart } from '../../../contexts/Cart/CartContextV2';

export default function removeItemsByProductIds(
  state: Cart,
  productIds: number[]
) {
  return {
    ...state,
    items: state.items.filter(
      (cartItem) => !productIds.includes(cartItem.productId)
    )
  };
}
