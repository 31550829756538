import { Validate3DSPayload } from './validate3DS';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const constructValidatePayload = (data: any): Validate3DSPayload => {
  if (data.Validated === true && data.ActionCode === 'SUCCESS') {
    return {
      eci: data.Payment.ExtendedData.ECIFlag,
      paResStatus: data.Payment.ExtendedData.PAResStatus,
      cavv: !!data.Payment.ExtendedData.CAVV,
      enrolled: ''
    };
  }

  if (data.CmpiLookup) {
    return {
      eci: data.CmpiLookup.EciFlag,
      paResStatus: data.CmpiLookup.PAResStatus,
      cavv: !!data.CmpiLookup.Cavv,
      enrolled: data.CmpiLookup.Enrolled
    };
  }

  return {
    eci: data.Payment.ExtendedData.EciFlag,
    paResStatus: data.Payment.ExtendedData.PAResStatus,
    cavv: !!data.Payment.ExtendedData.Cavv,
    enrolled: ''
  };
};

export default constructValidatePayload;
