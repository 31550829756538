import { FC, useEffect, useState } from 'react';
import DropdownItem from '../DropdownItem/DropdownItem';
import { styled } from '@mui/material/styles';
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps
} from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import {
  MenuItemModGroup,
  MenuModifierItem,
  ModItem
} from '../../../Menu/types';

interface RadioGroupProps {
  modName: string;
  group: MenuItemModGroup;
  selectedGroupItems: { [index: string]: ModItem };
  addSelection: (itemModifier: ModItem) => void;
  removeSelection: (itemModifier: ModItem) => void;
}

const DropdownRadioGroup = styled(MuiRadioGroup)<MuiRadioGroupProps>(({}) => ({
  flexWrap: 'nowrap'
}));

const StyledRadio = styled(Radio)(() => ({
  paddingLeft: '0px'
}));

const RadioGroup: FC<RadioGroupProps> = (props) => {
  const { modName, group, selectedGroupItems, addSelection, removeSelection } =
    props;

  const [currentSelection, setCurrentSelection] = useState<ModItem | null>(
    null
  );

  useEffect(() => {
    for (const key in selectedGroupItems) {
      if (!selectedGroupItems.hasOwnProperty(key)) {
        continue;
      }

      if (selectedGroupItems[key].modifierName === modName) {
        setCurrentSelection(selectedGroupItems[key]);
      }
    }
  }, [modName, selectedGroupItems]);

  const handleRadioChange = (item: MenuModifierItem) => {
    if (currentSelection) {
      removeSelection(currentSelection);
    }

    const selectedModifier: ModItem = {
      ...item,
      quantity: 1,
      modifierName: modName
    };

    addSelection(selectedModifier);
    setCurrentSelection(selectedModifier);
  };

  return (
    <DropdownRadioGroup
      name="radio-buttons-group"
      value={currentSelection?.name || ''}
    >
      {group.modItems.map((item, idx) => {
        return (
          <DropdownItem
            value={item}
            key={idx}
            data-testid={`${item.name}-radio`}
            className="radio"
          >
            <StyledRadio
              checked={currentSelection?.name === item.name}
              onChange={() => {
                handleRadioChange(item);
              }}
            />
          </DropdownItem>
        );
      })}
    </DropdownRadioGroup>
  );
};

export default RadioGroup;
