import { PHONE_COUNTRIES } from '../config/backendServicesEndpoints';
import { PhoneCountryResponse } from '../types/fetch';
import { http } from './http';
import { MuiTelInputCountry } from 'mui-tel-input';
import { AxiosResponse } from 'axios';

export const fetchPhoneCountries = async (): Promise<MuiTelInputCountry[]> => {
  return http
    .get(PHONE_COUNTRIES)
    .then((response: AxiosResponse<PhoneCountryResponse[]>) =>
      response.data.map(
        (country) => country.countryCode.toUpperCase() as MuiTelInputCountry
      )
    );
};
