import { Cart } from './cart';

export enum OrderStatus {
  DELAYED = 'DELAYED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED'
}

export enum OrderType {
  dineIn = 'dineIn',
  takeOut = 'takeOut'
}

export interface OrderState {
  eatId: number | null;
  restaurantId: string | null;
}

export type OrderActions = { type: 'set_order'; payload: OrderState };

export type OrderStatusItem = Cart;

export interface OrderStatusResponse {
  orderNum: number;
  restaurantId?: string;
  orderItem: OrderStatusItem;
  orderStatus: OrderStatus;
  completionTime?: number;
  pickupTime: Date | null;
}
