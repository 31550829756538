import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSiteConfig } from './useSiteConfig';
import { useStore } from './useStore';
import AppRoute from '../routing/AppRoute';
import { useCartV2 } from './useCartV2';

export const useEmptyCartRedirect = () => {
  const navigate = useNavigate();
  const { getQuantity } = useCartV2();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const cartQuantity = getQuantity();

  useEffect(() => {
    if (cartQuantity > 0 || !store) {
      return;
    }

    if (store && store.shortId !== '') {
      navigate(
        generatePath(AppRoute.MENU, {
          storeId: String(store.shortId)
        })
      );
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartQuantity, store]);

  return null;
};
